import React, { Component, Fragment, useState } from "react";
import Helmet from "./helmet";
import { StaticQuery, graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Transition } from "@headlessui/react";
// Localization
import { IntlProvider, FormattedMessage } from "react-intl";
import { Location, useLocation } from "@reach/router";
import { Footer } from "../footer";
import "./graphql-fragments";
import Redirect from "../i18link/redirect";
import { I18Link } from "../i18link";
import Button from "../button";
// Locale data
import en from "../../locale/en";
import fr from "../../locale/fr";
import de from "../../locale/de";
import ja from "../../locale/ja";

// Polyfill
import "intl-pluralrules";

const messages = { en, fr, de, ja };

export const SiteMapContext = React.createContext();

const LayoutInternal = ({
  children,
  pageId,
  locale,
  title,
  featuredImage,
  hero,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const staticQuery = useStaticQuery(graphql`
    query LayoutQueryClosing {
      site {
        ...SiteVersionQuery
        ...SiteTitleQuery
      }

      siteMap: allSitePage {
        ...LanguageMap
      }

      heroImage: file(relativePath: { eq: "pages/home/hero.jpg" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
        }
      }

      releases: allMarkdownRemark(
        filter: { frontmatter: { tags: { eq: "release" } } }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 4
      ) {
        edges {
          node {
            fields {
              id
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);

  const siteTitle =
    staticQuery && staticQuery.site && staticQuery.site.siteMetadata
      ? staticQuery.site.siteMetadata.title
      : "Screenpolo";

  const meta =
    staticQuery && staticQuery.site && staticQuery.site.siteMetadata
      ? staticQuery.site.siteMetadata
      : { version: "x.x" };

  const siteMap =
    staticQuery && staticQuery.siteMap && staticQuery.siteMap.edges
      ? staticQuery.siteMap.edges
      : [];

  const siteDownloadLinks =
    staticQuery && staticQuery.site && staticQuery.site.siteMetadata
      ? staticQuery.site.siteMetadata.downloadLinks
      : undefined;

  const siteMapPerId = {};
  siteMap.forEach((s) => {
    if (s.node && s.node.context && s.node.context.id) {
      siteMapPerId[s.node.context.id] = siteMapPerId[s.node.context.id] || {};

      siteMapPerId[s.node.context.id][s.node.context.language] = {
        text: s.node.context.name,
        path: s.node.path,
        matchPath: s.node.matchPath,
        id: s.node.context.id,
      };
    }
  });

  const locales = Object.keys(siteMapPerId[pageId]).map((language) => ({
    locale: language,
    ...siteMapPerId[pageId][language],
  }));

  // Adjust for client dynamic part
  const currentPage = siteMapPerId[pageId][locale];
  const basepath = currentPage.path;
  if (currentPage.matchPath && /\/\*$/.test(currentPage.matchPath)) {
    const suffix = location.pathname.replace(currentPage.path, "");
    locales.forEach((item) => {
      item.path += suffix;
    });
  }

  const titleToDisplay = title || siteTitle;

  return (
    <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
      <Fragment>
        <Helmet
          title={titleToDisplay}
          locale={locale}
          locales={locales}
          featuredImage={featuredImage}
        />
        <SiteMapContext.Provider
          value={{
            sitemap: siteMapPerId,
            locale,
            dl: siteDownloadLinks,
            basepath,
          }}
        >
          <div className={`flex justify-center content-center  bg-white h-screen `}>
            <div className=" bg-white flex flex-col justify-center">
            <div className="bg-blue-400 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl m-2">
               This website is closed.
            </div>

             
            </div>
          </div>
            

        </SiteMapContext.Provider>
      </Fragment>
    </IntlProvider>
  );
};

export default LayoutInternal;
