import React from 'react';
import PropTypes from 'prop-types';

const FormError = ({ list, color, title, ...rest }) => {
  if (!list || !list.length) {
    return null;
  }

  return (
    <div {...rest}>
      <div
        className={`block bg-${color}-100  text-${color}-600 py-4 px-4  my-4 rounded-sm text-sm`}
        role="alert"
      >
        {title && <p className="font-semibold uppercase text-sm mb-2">{title}</p>}
        <ul className="list-disc pl-4">
          {list.map((error, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`error_message_${index}`}>{error}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

FormError.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
  color: PropTypes.string,
};

FormError.defaultProps = {
  list: [],
  color: 'pink',
  title: 'Error',
};

export default FormError;
