import React from "react";
import PropTypes from "prop-types";

export const Email = ({ children, icon, ...others }) => (
  <a {...others} href="mailto:contact@screenpolo.com">
    {icon && (
      <span className="icon">
        <i className="fa fa-envelope" />{" "}
      </span>
    )}
    {children || "contact@screenpolo.com"}
  </a>
);

Email.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
