import fetch from 'isomorphic-fetch';
import { GRAPHQL_ENDPOINT } from '../constants';

const formatError = (error = {}) => {
  const errorMessages = [];
  if (error.graphQLErrors && error.graphQLErrors.length) {
    error.graphQLErrors.forEach(({ message }) => errorMessages.push(message));
  }

  if (error.networkError) {
    if (
      error.networkError.result &&
      error.networkError.result.errors &&
      error.networkError.result.errors.length
    ) {
      error.networkError.result.errors.forEach(({ message }) =>
        errorMessages.push(message)
      );
    }

    errorMessages.push(error.networkError.message);
  }

  return errorMessages;
};

export const directQuery = (query) =>
  fetch(`${GRAPHQL_ENDPOINT}`, {
    credentials:
      process.env.NODE_ENV === 'development' ? 'include' : 'same-origin',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query }),
  }).then((response) => response.json());

export default formatError;
