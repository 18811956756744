import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import usePrevious from "./hooks/use-previous";
const Button = ({
  simple,
  size,
  loading,
  inverted,
  disabled,
  color,
  onClick,
  children,
  icon,
  className,
  ...rest
}) => {
  const [isLoading, setLoading] = useState(loading);
  const previousLoading = usePrevious(loading);
  useEffect(() => {
    if (previousLoading !== loading) {
      setLoading(loading);
    }
  }, [previousLoading, loading]);

  const colorIntensity = inverted ? "600" : "500";
  const colorIntensityHover = inverted ? "500" : "600";

  const simpleColorIntensity = inverted ? "600" : "400";
  const simpleColorIntensityHover = inverted ? "400" : "600";

  // white, black, gray, red, orange, yellow, green, teal, blue, indigo, purple, pink, cool-gray

  let focusRingClass;
  let borderColorClass;
  let hoverBorderColorClass;
  let bgColorClass;
  let hoverBgColorClass;
  let textColorClass;
  let loadingTextColorClass;

  switch (color) {
    case "pink":
      focusRingClass = inverted ? "focus:ring-pink-600" : "focus:ring-pink-500";
      if (simple) {
        borderColorClass = inverted ? "border-pink-600" : "border-pink-400";
        hoverBorderColorClass = inverted ? "hover:border-pink-400" : "hover:border-pink-600";
      }
      else {
        borderColorClass = inverted ? "border-pink-600" : "border-pink-500";
        hoverBorderColorClass = inverted ? "hover:border-pink-500" : "hover:border-pink-600";
      }

      bgColorClass = inverted ? "bg-pink-600" : "bg-pink-500";
      hoverBgColorClass = inverted ? "hover:bg-pink-500" : "hover:bg-pink-600";

      textColorClass =  "text-pink-600";
      loadingTextColorClass =  "text-pink-700";
      break;
    case "blue":
      focusRingClass = inverted ? "focus:ring-blue-600" : "focus:ring-blue-500";
      if (simple) {
        borderColorClass = inverted ? "border-blue-600" : "border-blue-400";
        hoverBorderColorClass = inverted ? "hover:border-blue-400" : "hover:border-blue-600";
      }
      else {
        borderColorClass = inverted ? "border-blue-600" : "border-blue-500";
        hoverBorderColorClass = inverted ? "hover:border-blue-500" : "hover:border-blue-600";
      }

      bgColorClass = inverted ? "bg-blue-600" : "bg-blue-500";
      hoverBgColorClass = inverted ? "hover:bg-blue-500" : "hover:bg-blue-600";

      textColorClass =  "text-blue-600";
      loadingTextColorClass =  "text-blue-700";
      break;
    case "indigo":
      focusRingClass = inverted ? "focus:ring-indigo-600" : "focus:ring-indigo-500";
      if (simple) {
        borderColorClass = inverted ? "border-indigo-600" : "border-indigo-400";
        hoverBorderColorClass = inverted ? "hover:border-indigo-400" : "hover:border-indigo-600";
      }
      else {
        borderColorClass = inverted ? "border-indigo-600" : "border-indigo-500";
        hoverBorderColorClass = inverted ? "hover:border-indigo-500" : "hover:border-indigo-600";
      }

      bgColorClass = inverted ? "bg-indigo-600" : "bg-indigo-500";
      hoverBgColorClass = inverted ? "hover:bg-indigo-500" : "hover:bg-indigo-600";

      textColorClass =  "text-indigo-600";
      loadingTextColorClass =  "text-indigo-700";
      break;
    case "green":
      focusRingClass = inverted ? "focus:ring-green-600" : "focus:ring-green-500";
      if (simple) {
        borderColorClass = inverted ? "border-green-600" : "border-green-400";
        hoverBorderColorClass = inverted ? "hover:border-green-400" : "hover:border-green-600";
      }
      else {
        borderColorClass = inverted ? "border-green-600" : "border-green-500";
        hoverBorderColorClass = inverted ? "hover:border-green-500" : "hover:border-green-600";
      }
    
      bgColorClass = inverted ? "bg-green-600" : "bg-green-500";
      hoverBgColorClass = inverted ? "hover:bg-green-500" : "hover:bg-green-600";
    
      textColorClass =  "text-green-600";
      loadingTextColorClass =  "text-green-700";
      break;
    case "red":
      focusRingClass = inverted ? "focus:ring-red-600" : "focus:ring-red-500";
      if (simple) {
        borderColorClass = inverted ? "border-red-600" : "border-red-400";
        hoverBorderColorClass = inverted ? "hover:border-red-400" : "hover:border-red-600";
      }
      else {
        borderColorClass = inverted ? "border-red-600" : "border-red-500";
        hoverBorderColorClass = inverted ? "hover:border-red-500" : "hover:border-red-600";
      }
    
      bgColorClass = inverted ? "bg-red-600" : "bg-red-500";
      hoverBgColorClass = inverted ? "hover:bg-red-500" : "hover:bg-red-600";
    
      textColorClass =  "text-red-600";
      loadingTextColorClass =  "text-red-700";
      break;
    case "gray":
      focusRingClass = inverted ? "focus:ring-gray-600" : "focus:ring-gray-500";
      if (simple) {
        borderColorClass = inverted ? "border-gray-600" : "border-gray-400";
        hoverBorderColorClass = inverted ? "hover:border-gray-400" : "hover:border-gray-600";
      }
      else {
        borderColorClass = inverted ? "border-gray-600" : "border-gray-500";
        hoverBorderColorClass = inverted ? "hover:border-gray-500" : "hover:border-gray-600";
      }
    
      bgColorClass = inverted ? "bg-gray-600" : "bg-gray-500";
      hoverBgColorClass = inverted ? "hover:bg-gray-500" : "hover:bg-gray-600";
    
      textColorClass =  "text-gray-600";
      loadingTextColorClass =  "text-gray-700";
      break;
    case "orange":
      focusRingClass = inverted ? "focus:ring-orange-600" : "focus:ring-orange-500";
      if (simple) {
        borderColorClass = inverted ? "border-orange-600" : "border-orange-400";
        hoverBorderColorClass = inverted ? "hover:border-orange-400" : "hover:border-orange-600";
      }
      else {
        borderColorClass = inverted ? "border-orange-600" : "border-orange-500";
        hoverBorderColorClass = inverted ? "hover:border-orange-500" : "hover:border-orange-600";
      }
    
      bgColorClass = inverted ? "bg-orange-600" : "bg-orange-500";
      hoverBgColorClass = inverted ? "hover:bg-orange-500" : "hover:bg-orange-600";
    
      textColorClass =  "text-orange-600";
      loadingTextColorClass =  "text-orange-700";
      break;
    default:
      throw new Error(`Color ${color} is not supported yet.`)
      break;
  }

  let cssClasses = `focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusRingClass} font-medium ${
    disabled || isLoading || loading ? "opacity-75 cursor-not-allowed" : ""
  }`;
  if (simple) {
    cssClasses += `  hover:bg-gray-100 ${textColorClass} px-3 rounded border ${borderColorClass} ${hoverBorderColorClass}`;
  } else {
    cssClasses += ` text-white px-4 rounded ${bgColorClass} ${hoverBgColorClass} text-white`;
  }

  let svgClasses = `${children ? "md:mr-2" : ""} stroke-current`;

  // Size

  if (size === "small") {
    cssClasses += " py-1 text-xs";
    svgClasses += " h-4 w-4 ";
  }

  if (size === "regular") {
    cssClasses += " py-2 text-sm";
    // svgClasses += " h-4 w-4 ";

  }

  if (size === "big") {
    cssClasses += " py-4 md:text-lg md:px-10";
    svgClasses += " h-8 w-8 ";
  }

  return (
    <button
      {...rest}
      className={`${cssClasses} ${className}`}
      type="button"
      onClick={(e) => {
        if (!isLoading && !disabled && onClick) {
          setLoading(true);
          Promise.resolve(onClick(e)).then((ret) => {
            setLoading(false);
            return ret;
          });
        }
      }}
    >
      <div className="flex items-center justify-center ">
        {isLoading || loading ? (
          <>
            <svg
              className={`animate-spin ${children ? "-ml-1 mr-3" : ""} h-5 w-5 ${simple ? `${loadingTextColorClass}` : "text-white"}`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            {children && <span className="hidden md:inline">{children}</span>}
          </>
        ) : (
          <>
            {icon && (
              <span className={`icon ${children ? "mr-3" : ""}`}>
                <i className={`fa fa-${icon}`} />{" "}
              </span>
            )}
            {children}
          </>
        )}
      </div>
    </button>
  );
};

Button.propTypes = {
  simple: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  inverted: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  simple: false,
  loading: false,
  disabled: false,
  inverted: false,
  onClick: undefined,
  icon: undefined,
  color: "blue",
  size: "regular",
  className: "",
  children: null,
};

export default Button;
