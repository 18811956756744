const locale = {
  // ***********************************************
  // URLs
  // ***********************************************
  "page.index.url": "",
  "page.index.name": "Start",
  "page.index.title":
    "Screenpolo: das Bildschirmaufnahme-Tool für Produktivität",

  "page.features.url": "funktionen",
  "page.features.name": "Funktionen",
  "page.features.title": "Funktionen von screenpolo",

  "page.pricing.url": "preise",
  "page.pricing.name": "Preise",
  "page.pricing.title": "Preise",

  "page.upgrade.url": "Aktualisierung",
  "page.upgrade.name": "Aktualisierung",
  "page.upgrade.title": "Aktualisieren Sie auf die neueste Version",

  "page.volume-discount.url": "mengenrabatt",
  "page.volume-discount.name": "Mengenrabatt",
  "page.volume-discount.title": "Mengenrabatt",

  "page.download.url": "download",
  "page.download.name": "Download",
  "page.download.title": "Download screenpolo",

  "page.downloading.url": "downloading-exe",
  "page.downloading.name": "Download of screenpolo.exe",
  "page.downloading.title": "Downloading...",

  "page.downloading-msi.url": "downloading-msi",
  "page.downloading-msi.name": "Download of the msi",
  "page.downloading-msi.title": "Downloading the MSI...",

  "page.company.url": "uber-uns",
  "page.company.name": "Über uns",
  "page.company.title": "Über uns",

  "page.support.url": "support",
  "page.support.name": "Support",
  "page.support.title": "Help center",

  "page.privacy-policy.url": "datenschutz-bestimmungen",
  "page.privacy-policy.name": "Datenschutz-Bestimmungen",
  "page.privacy-policy.title": "Datenschutz-Bestimmungen",

  "page.affiliate-program.url": "partnerprogramm",
  "page.affiliate-program.name": "Partnerprogramm",
  "page.affiliate-program.title": "Partnerprogramm",

  "page.releases.url": "softwareveroffentlichung",
  "page.releases.name": "Softwareveröffentlichung",
  "page.releases.title": "Softwareveröffentlichung",

  "page.news.url": "Nachrichten",
  "page.news.name": "Was gibt's Neues?",
  "page.news.title": "Screenpolo: Was ist neu?",

  "page.third-parties.url": "third-parties",
  "page.third-parties.name": "Third parties",
  "page.third-parties.title": "Third parties",

  "page.software-license-agreement.url": "softwarelizenzvertrag",
  "page.software-license-agreement.name": "Softwarelizenzvertrag",
  "page.software-license-agreement.title": "Softwarelizenzvertrag",

  "page.translation.url": "ubersetzung",
  "page.translation.name": "Übersetzung",
  "page.translation.title": "Über Übersetzer",

  "page.install-survey.url": "installation-subersicht",
  "page.install-survey.name": "Installationsübersicht",
  "page.install-survey.title": "Installationsübersicht von screenpolo",

  "page.uninstall-survey.url": "uninstall-umfrage",
  "page.uninstall-survey.name": "Umfrage beenden",
  "page.uninstall-survey.title": "Deinstallation / Beenden von screenpolo",

  "page.purchase-survey.url": "nach-dem-kauf-umfrage",
  "page.purchase-survey.name": "Nach dem Kauf Umfrage",
  "page.purchase-survey.title": "Nach dem Kauf Umfrage",

  "page.thank-you.url": "danke",
  "page.thank-you.name": "Danke",
  "page.thank-you.title": "Danke",
  
  "page.lost-key.url": "verlorener-schlussel",
  "page.lost-key.name": "Verloren Ihr Lizenzschlüssel?",
  "page.lost-key.title": "Verloren Ihr Lizenzschlüssel?",

  "page.reseller.url": "Wiederverkaufer",
  "page.reseller.name": "Wiederverkäufergeschäft",
  "page.reseller.title": "Wiederverkäuferspezifischer Shop",

  "page.cloud.url": "cloud",
  "page.cloud.name": "Cloud",
  "page.cloud.title": "screenpolo Cloud",

  // ***********************************************
  // Header and footer
  // ***********************************************

  "top-menu.languages": "Sprachen",

  "top-menu.support.license-section": "Lizenz",
  "top-menu.support.enterprise-section": "Unternehmen",
  "top-menu.support.support-section": "Unterstützung",
  "top-menu.support.help-center-entry": "Hilfezentrum",


  "footer.site": "Site",
  "footer.contact-us": "Kontaktiere uns",
  "footer.social-networks": "Soziale Netzwerke",
  "footer.recent-releases": "Aktuelle Versionen",
  "footer.languages": "Sprachen",
  "footer.copyrights": "Alle Rechte vorbehalten",

  "footer.subscribe-newsletter.title": "Abonnieren Sie unseren Newsletter",
  "footer.subscribe-newsletter.description": "Die neuesten Nachrichten und Veröffentlichungen werden wöchentlich an Ihren Posteingang gesendet.",
  "footer.subscribe-newsletter.email": "E-Mail-Addresse",
  "footer.subscribe-newsletter.enter-email": "Geben sie ihre E-Mail Adresse ein",
  "footer.subscribe-newsletter.subscribe": "Abonnieren",
  "footer.subscribe-newsletter.email-added": "Ihre E-Mail wurde hinzugefügt!",

  // ***********************************************
  // Home
  // ***********************************************

  "index.title": "Bilder und Videos Bildschirmerfassung",
  "index.sub-title":
    "Spart Zeit, wenn Sie Kollegen und Kunden etwas erklären wollen.",
  "index.push-line": "Ein Screenshot sagt mehr als Tausend Worte!",
  "index.description.part-1":
    "<strong>screenpolo</strong> erfasst Ihren Desktop (<strong>Screenshots und HD-Videos</strong>) für Ihre Schulungsdokumente, gemeinsame Designarbeit, IT-Fehlerberichte und mehr…",
  "index.description.part-2":
    "screenpolo ist ein NEUES leichtgewichtiges <strong>Bildschirmerfassungs-Tool</strong> mit integriertem <strong>Bildeditor</strong>, Benutzerhandbuch-Generator und Teilen-Optionen.",
  "index.main-button.text": "Holen Sie sich screenpolo GRATIS",
  "index.main-button.sub-text": "Alle Windows-Versionen",
  "index.more-features-button": "Entdecken Sie weitere Funktionen…",
  "index.feature-highlight.capture.title": "Erfassen Sie in Bilder oder Videos",
  "index.feature-highlight.capture.content":
    "<strong>Erfassen Sie das, was Sie auf Ihrem Bildschirm sehen</strong>, mit nur wenigen Klicks.{br}{br}Verwalten Sie Ihren <strong>Erfassungsverlauf</strong> nach Arbeitsplätzen.",

  "index.feature-highlight.editor.title":
    "Erstellen Sie professionell aussehende Bilder und Dokumente",
  "index.feature-highlight.editor.content":
    "Bearbeiten und <strong>heben Sie Wichtiges in Ihren Screenshots hervor</strong> – mit dem <strong>integrierten Bildeditor</strong>.{br}{br}Erstellen Sie PDF-Dokumente mit unserem <strong>Dokumentgenerator</strong>.",

  "index.feature-highlight.sharing.title": "Teilen Sie Ihre Bildschirmerfassungen",
  "index.feature-highlight.sharing.content":
    "Fügen Sie Bildschirmerfassungen per Drag & Drop in E-Mails ein, <strong>für einen schnellen Arbeitsablauf!</strong>.{br}{br}Teilen Sie Bilder und Videos mit <strong>screenpolo Cloud – Nutzen Sie die screenpolo Cloud ohne Registrierung</strong>.{br}{br}Teilen Sie Bilder und Videos bei <strong>Evernote, Google Drive, Twitter, Facebook, Dropbox</strong> und vielen anderen Diensten.",

  "index.testimonials.title": "Was unsere Kunden sagen",
  "index.referrals.title": "Täglich von großen Unternehmen eingesetzt",
  "index.download-macos-version": "Laden Sie Ihre MacOs-Version herunter",

  // ***********************************************
  // Features
  // ***********************************************

  "features.title": "Funktionen",
  "features.pre-title": "Übernehmen Sie die Kontrolle über Ihre Aufnahmen.",
  "features.post-title": "Schauen Sie sich unsere Feature-Tour an.",

  // ***********************************************
  // Download
  // ***********************************************

  
  "download.title": "Laden Sie die NEUE Version GRATIS herunter.",
  "download.pre-title": "Laden Sie die NEUE Version herunter",
  "download.post-title": "kostenlos",
  "download.download": "Download",

  "download.latest-version": "Letzte Version ({version})",
  "download.release-note": "Versionshinweis",
  "download.user-manual": "Benutzerhandbuch",
  "download.not-supported": "MacOS, iOS und Android werden nicht unterstützt",
  "download.os-types": "32 und 64 bits",
  "download.languages.main": "Englisch, Spanisch, Französisch, Deutsch und {more}",
  "download.languages.more": "15 weitere",
  "download.languages.rest":
    "Italienisch, Vereinfachtes Chinesisch, Traditionelles Chinesisch, Türkisch, Georgisch, Russisch, Ukrainisch, Brasilianisch, Portugiesisch, Japanisch, Arabisch, Polnisch, Dänisch",
  "download.exe-prerequisites": "{dotnet} oder höher ist erforderlich.",
  "download.exe-button.text": "Laden Sie screenpolo herunter ({size})",
  "download.exe-button.sub-text": "Portable Anwendung mit enthaltenem Setup",
  "download.msi-version": "Unternehmensbereitstellung ({Version})",
  "download.msi-description":
    "Komplettpaket für die Installation von screenpolo in Unternehmen.",
  "download.msi-prerequisites":
    "{dotnet} und Administratorrechte sind erforderlich.",
  "download.msi-button.text": "Setup herunterladen msi ({size})",
  "download.msi-button.sub-text": "Bevorzugt für den Einsatz in Unternehmen",

  // ***********************************************
  // Release note
  // ***********************************************
  
  "release.whatsnew": "Was gibt's Neues?",
  "release.tags.latest": "NEUESTE",
  "release.previous-release": "Vorherige Version",
  "release.latest-release": "Neueste Erscheinung",
  "release.menu.older-releases": "Ältere Versionen anzeigen",
  "release.menu.all-releases": "Alle Veröffentlichungen anzeigen",
  "release.download-button": "Laden Sie diese Version herunter",
  "release.new-version-warning.header": "Eine neue Version ist verfügbar",
  "release.new-version-warning.content":
  "Klicken Sie hier, um die neueste Version zu sehen ({version})",
  "release.update.label": "auf Update überprüfen",
  "release.how-to-upgrade": "Wie aktualisiere ich?",
  "release.update.alternative": "oder {checkForUpdate} um das KOSTENLOSE Update zu erhalten.",

  // ***********************************************
  // Blog
  // ***********************************************
  
  "blog.read-article": "Lesen Sie die ganze Geschichte",
  

  // ***********************************************
  // Pricing
  // ***********************************************
  "pricing.title": "Entdecken Sie, wie screenpolo Ihre Produktivität steigern kann.",
  "pricing.sub-title": "Mehr als 1.000.000 Nutzer.",
  "pricing.faq": "Haben Sie Fragen?",

  "pricing.plan.base": "BASE",
  "pricing.plan.from":
    "Alle Funktionen von screenpolo {version} sind enthalten",
  "pricing.tax": "(inkl. {tax} VAT)",
  "pricing.black-friday": "<p>50% Rabatt mit dem Code <strong>BLACK_FRIDAY_2019</strong> auf screenpolo PRO</p>",

  // Free Plan
  // -----------
  "pricing.free-plan.product": "GRATIS",
  "pricing.free-plan.recommended-for": "100% GRATIS",
  "pricing.free-plan.sub-title": "updates{br}erforderlich",
  "pricing.free-plan.price": "GRATIS",
  "pricing.free-plan.features.image-capture": "Bilderfassung",
  "pricing.free-plan.features.video-capture": "HD-Video-Erfassung",
  "pricing.free-plan.features.branding": "mit Branding",
  "pricing.free-plan.features.limited-editor": "Begrenzter Bildeditor",
  "pricing.free-plan.features.at-work": "Kommerzielle Nutzung",

  // Free Plan - action button
  "pricing.free-plan.action.get": "Holen Sie sich Ihre GRATIS-Version",

  // Pro Plan
  // -----------
  "pricing.pro-plan.product": "PRO",
  "pricing.pro-plan.recommended-for": "FREIBERUFLER UND KLEINE TEAMS",
  "pricing.pro-plan.sub-title":
    "pro Benutzer{br}<strong>unbefristete Lizenz</strong>",

  // Pro Plan - capture section
  "pricing.pro-plan.features.title.capture": "Bilderfassung",
  "pricing.pro-plan.features.video-capture": "HD-Video-Erfassung",
  "pricing.pro-plan.features.video-capture-details":
    "einschließlich Systemtonaufnahme",
  "pricing.pro-plan.features.android-capture": "Android-Erfassung",
  "pricing.pro-plan.features.text-capture": "OCR (Text für das Bild kopieren)",
  "pricing.pro-plan.features.color-picker": "Farbwähler",

  // Pro Plan - edit, organize and share section
  "pricing.pro-plan.features.title.edit-organize": "BEARBEITEN, ORGANISIEREN",
  "pricing.pro-plan.features.editor": "Voll ausgestatteter Bildbearbeitungsprogramm",
  "pricing.pro-plan.features.editor-details":
    "inklusive Nacharbeit, Effekten und Wasserzeichen",
  "pricing.pro-plan.features.doc-generator": "Dokumentgenerator",
  "pricing.pro-plan.features.video-editor": "Video Clipping und Zusammenführung",
  "pricing.pro-plan.features.video-editor-details": "(Anfang und Ende)",
  "pricing.pro-plan.features.workspaces": "Mehrere Arbeitsbereiche",
  "pricing.pro-plan.features.sharing": "Funktionen teilen",

  // Pro Plan - pro section
  "pricing.pro-plan.features.title.pro": "PRO",
  "pricing.pro-plan.features.support": "Prioritäts-Support (1 Jahr)",
  "pricing.pro-plan.features.policies": "Richtlinien",
  "pricing.pro-plan.features.policies-details":
    "(für Administratoren zum Deaktivieren einiger Funktionen)",

  // Pro Plan - action button
  "pricing.pro-plan.action.buy": "JETZT Kaufen",
  "pricing.pro-plan.action.guarantee": "30 Tage Geld-zurück-Garantie",

  // Site Plan
  // -----------
  "pricing.site-plan.product": "Firmenlizenz",
  "pricing.site-plan.recommended-for": "UNTERNEHMEN",
  "pricing.site-plan.sub-title":
    "<strong>unbegrenzte Benutzer</strong>{br}unbefristete Lizenz",

  "pricing.site-plan.discount": "{price} bis zum 31.05.2019",

  // Site Plan - enterprise section
  "pricing.site-plan.features.title.enterprise": "UNTERNEHMEN",
  "pricing.site-plan.features.virtualization":
    "Erforderlich für Citrix, TSM, VMWare VDI",
  "pricing.site-plan.features.virtualization-details":
    "oder für große deploymentI",
  "pricing.site-plan.features.no-internet": "Keine Internetverbindung erforderlich",
  "pricing.site-plan.features.simple-activation": "Einfacher Aktivierungsprozess",

  // Site Plan - action button
  "pricing.site-plan.action.contact-us": "Kontaktiere Sie uns",
  "pricing.site-plan.action.purpose": "Für Angebotsanforderungen und Bestellungen auf Rechnung",

  // ***********************************************
  // Volume discount
  // ***********************************************
  "volume-discount.title": "Mengenrabatt",
  "volume-discount.sub-title":
    "Teilen Sie die alle Funktionen umfassende Version von screenpolo mit Ihrem ganzen Team.",
  "volume-discount.quantity": "Menge",
  "volume-discount.price": "Preis",
  "volume-discount.buy": "Kaufen",
  "volume-discount.license-pack":
    "Pack: {count, number} {count, plural, one {Einzellizenz} other {Einzellizenen}}",
  "volume-discount.buy-for":
    "{count, number} {count, plural, one {Einzellizenz} other {Einzellizenen}} ({count, number} Benutzer)",
  "volume-discount.price-per-unit": "{price}",
  "volume-discount.discount": "{percentage} Rabatt",
  "volume-discount.save": "Mengenrabatt, Ersparnis {value}",
  "volume-discount.enter-quantity": "Ihre Anzahl von Benutzern",
  "volume-discount.site-license": "Site-Lizenz: unbegrenzte Benutzer",
  "volume-discount.unlimited": "Kaufen Sie für alle Benutzer",

  // ***********************************************
  // Upgrade
  // ***********************************************
  "upgrade.title": "Aktualisieren Sie screenpolo auf die neueste Version.",
  "upgrade.sub-title": "Erneuern Sie, um neue Funktionen und Updates für ein weiteres Jahr zu erhalten.",
  "upgrade.enter-your-key": "Geben Sie Ihren Lizenzschlüssel ein, um Ihren Preis zu erhalten",
  "upgrade.lost-your-key": "Lizenzschlüssel verloren?",
  "upgrade.buy-new-one": "Neukunde? Kaufen Sie eine Lizenz",
  "upgrade.validate": "Überprüfen Sie Ihren Schlüssel",
  "upgrade.upgrade-plan": "Upgrade-Plan",
  "upgrade.renewal-price": "Preis für eine Verlängerung",
  "upgrade.invalid-key": "Ihr Schlüssel ist ungültig",
  "upgrade.pro": "Aktualisieren {name} für {price}",
  "upgrade.tax": "(inkl. {tax} VAT)",
  "upgrade.volume-discount": "Mengenrabatte gelten automatisch.",

  // ***********************************************
  // Reseller
  // ***********************************************
  "reseller.title": "Wiederverkäufer-Laden",
  "reseller.sub-title":
    "Kaufen Sie screenpolo im Auftrag Ihrer Kunden.",
  "reseller.product-selection": "Wählen Sie das zu bestellende Produkt aus",
  "reseller.request-reseller-company": "Ihr Firmenname (Abrechnung)",
  "reseller.request-reseller-email": "Ihre E-Mail (Abrechnung)",
  "reseller.request-end-user-company": "Der Firmenname des Endkunden (für den Lizenzschlüssel)",
  "reseller.request-end-user-email": "Die E-Mail des Endkunden (für den Lizenzschlüssel)",

  "reseller.missing-product": "Ein Produkt ist nicht ausgewählt",
  "reseller.missing-product-quantity": "Die Menge ist leer",
  "reseller.missing-reseller-company": "Die Wiederverkäuferfirma darf nicht leer sein",
  "reseller.invalid-missing-reseller-email": "Die Reseller-E-Mail ist leer oder ungültig",
  "reseller.missing-end-user-company-name": "Die Endbenutzerfirma darf nicht leer sein",
  "reseller.invalid-missing-end-user-email": "Die E-Mail des Endbenutzers ist leer oder ungültig",

  "reseller.regular-price": "Normalpreis: {value} (inkl. Steuern)",
  "reseller.discount": "Reseller-Rabatt (inkl. Mengenrabatt): {value}",
  "reseller.total": "Gesamt: {value} (inkl. Steuer: {tax})",
  "reseller.user-quantity": "Anzahl der Endbenutzer",

  // ***********************************************
  // Support
  // ***********************************************
  "support.title": "F.A.Q.",
  "support.sub-title": "Häufig gestellte Fragen",
  "support.categories.installation": "Installation",
  "support.categories.editor": "Eingebauter Editor",
  "support.categories.licensing": "Lizenzierung",
  "support.categories.others": "Andere",
  "support.categories.screenshot": "Bildschirmfoto",
  "support.categories.video-capture": "Videoaufnahme",
  "support.categories.troubleshooting": "Fehlerbehebung",
  "support.categories.upgrade": "Upgrades",
  "support.see-also": "Siehe auch...",

  // ***********************************************
  // Company / About us
  // ***********************************************
  "company.title": "screenpolo wird von Learnpulse SAS herausgegeben",
  "company.pre-title":
    "Wir fertigen Screen-Capture-Lösungen von Hand",
  "company.post-title":
    "Hergestellt in Toulouse",
  "company.who-are-we.title": "Wer sind wir ?",
  "company.who-are-we.content.part1":
    "<strong>screenpolo</strong> wird von <strong>LEARNPULSE SAS</strong> , einer 2012 gegründeten Unternehmen.",
  "company.who-are-we.content.part2":
    "{bjamin} (Direktor, links) und {jcbaey} (CEO, rechts, {twitter_jc}) sind die Mitbegründer von Learnpulse SAS. Dank ihren Erfahrungen in großen Unternehmen haben sie screenpolo  mit einem Blick für Produktivität und Ergonomie gestaltet und entwickelt.",
  "company.who-are-we.content.part3":
    "Learnpulse SAS ist Teil des Microsoft-Startup-Programms {link}",
  "company.contact-us": "Kontaktiere uns",
  "company.email.title": "Email",
  "company.email.content":
    "Wenn Sie Hilfe, Support oder Infos für Werbung, Presse oder Investoren benötigen, kontaktieren Sie uns bitte unter:",
  "company.postal-address": "Postanschrift",
  "company.social-medias": "Soziale Medien",
  "company.legal-information": "Rechtliches",
  "company.company-type": "(Französische vereinfachte Aktiengesellschaft)",
  "company.registration-number": "Registrierungsnummer (KBIS)",
  "company.capital": "Kapital",
  "company.headquarters": "Eingetragener Hauptsitz",
  "company.siret": "SIRET",
  "company.vat": "VAT ID / Umsatzsteuer-Identifikationsnummer",

  // ***********************************************
  // Downloading
  // ***********************************************

  "downloading.title": "Wird heruntergeladen...",
  "downloading.sub-title": "Der Download startet in wenigen Sekunden ...",
  "downloading.content.title": "Vielen Dank für das Herunterladen von screenpolo!",
  "downloading.content.text":
    "Wenn der Download nicht startet, klicken Sie auf {link}.",
  "downloading.content.release-btn": "Lesen Sie unsere Release-Information",
  "downloading.link": "direkte Verbindung",
  "downloading.link-title": "Klicke hier zum herunterladen",
  "subscribe-modal.title": "Möchten Sie unsere neuesten Nachrichten nicht verpassen?",
  "subscribe-modal.placeholder": "deine E-Mail",
  "subscribe-modal.text":
    "Wir senden Ihnen einige E-Mails, damit Sie beginnen können. Abmeldung jederzeit möglich.",
  "subscribe-modal.skip": "Überspringen",
  "subscribe-modal.invalid-email": "Ihre E-Mail-Adresse ist leer oder ungültig",

  "releases.title": "Veröffentlichungen",
  "releases.sub-title": "Alle screenpolo-Versionen",

  // ***********************************************
  // Components
  // ***********************************************

  "release-image.new": "NEU",
  "release-image.version": "VERSION VERFÜGBAR",

  // ***********************************************
  // Installation survey
  // ***********************************************
  "survey.install.title": "Installationsübersicht",
  "survey.install.sub-title":
    "Bitte helfen Sie uns, Sie mit ein paar Fragen besser zu kennen!",
  "survey.install.time-to-complete":
    "Nur 30 Sekunden Ihrer Zeit, versprechen wir Ihnen!",
  "survey.install.time-to-complete.subtitle": "Sie können alle Fragen überspringen.",

  "survey.features": "Was sind Ihre am häufigsten verwendeten Funktionen von screenpolo?",
  "survey.features.screenshot": "Bilderfassung (Screenshot)",
  "survey.features.screencast": "Videoaufnahme (Screencast)",
  "survey.features.img-editor": "Bildbearbeiter",
  "survey.features.scrolling-capture": "Scroll-Fenster erfassen",
  "survey.features.sharing": "Teilen",

  "survey.where": "Wo verwenden Sie screenpolo?",
  "survey.where.home": "Zuhause",
  "survey.where.work": "Auf Arbeit",

  "survey.installation-count":
    "Auf wie vielen Computern hast du screenpolo installiert?",

  "survey.age": "Dein Alter",

  "survey.from": "Wo haben Sie von screenpolo gehört? Kostenlose Kommentare",
  "survey.tell-us-more": "Erzähl uns mehr über dich ...",

  "survey.email": "Ihre E-Mail, damit wir Ihnen antworten können!",

  "survey.social": "Vergesst nicht, uns in sozialen Netzwerken zu folgen",

  // ***********************************************
  // Survey components
  // ***********************************************
  "survey.submit": "Reichen Sie Ihre Antwort ein",

  "survey.jobs": "Ihre Arbeit, Arbeitsbereich",
  "survey.jobs.architecture": "Die Architektur",
  "survey.jobs.cad": "CAD",
  "survey.jobs.education": "Bildung",
  "survey.jobs.graphics": "Grafik",
  "survey.jobs.management": "Management",
  "survey.jobs.sw": "Software-Entwicklung",
  "survey.jobs.support": "Unterstützung",
  "survey.jobs.test": "Prüfung",
  "survey.jobs.other": "Andere",

  // ***********************************************
  // Uninstallation survey
  // ***********************************************
  "survey.uninstall.title": "Deinstallationserhebung",
  "survey.uninstall.sub-title":
    "Ihre Meinung ist uns wichtig! Bitte teilen Sie uns mit, warum Sie screenpolo deinstalliert haben.",
  "survey.uninstall.time-to-complete":
    "Nur 30 Sekunden Ihrer Zeit, versprechen wir Ihnen!",
  "survey.uninstall.time-to-complete.subtitle": "Sie können alle Fragen überspringen.",

  "survey.uninstall.reason": "Warum haben Sie screenpolo deinstalliert?",
  "survey.uninstall.reason.other": "Andere Gründe",
  "survey.uninstall.reason.re-install": "Ich werde es auf einem anderen Computer installieren",
  "survey.uninstall.reason.new-version": "Ich möchte auf die neueste Version aktualisieren",
  "survey.uninstall.reason.missing-feature": "Ein wichtiges Merkmal fehlt",
  "survey.uninstall.reason.alternatives": "Es gibt bessere Alternativen",
  "survey.uninstall.reason.cpu-issue": "Es verwendet zu viel CPU-Ressourcen oder ist zu langsam",
  "survey.uninstall.reason.unstable": "Es ist nicht stabil genug",
  "survey.uninstall.reason.antivirus-issue": "Meine Firewall oder Antivirensoftware warnt mich",
  "survey.uninstall.reason.not-used": "Ich benutze es nicht mehr",

  "survey.uninstall-details": "Welche Funktion fehlt? Welcher Fehler ist aufgetreten? Was sollte geändert werden? (freie Kommentare)",
  "survey.uninstall.email": "Ihre E-Mail, damit wir Ihnen antworten können!",

  "survey.uninstall.missing-reason": "Bitte wählen Sie, warum Sie deinstallieren",

  // ***********************************************
  // Thank you page
  // ***********************************************  
  "thank-you.title": "Danke!",
  "thank-you.sub-title": "Vielen Dank für Ihre Zeit und Ihre Antwort.",
  "thank-you.support": "Vergiss nicht unsere Tutorials anzuschauen",
  "thank-you.support.button": "Mehr sehen...",

  // ***********************************************
  // Purchase survey
  // ***********************************************
  "survey.purchase.title": "Kauf Umfrage",
  "survey.purchase.sub-title":
    "Bitte helfen Sie uns, Sie mit ein paar Fragen besser zu kennen!",
  "survey.purchase.time-to-complete":
    "Nur 30 Sekunden Ihrer Zeit, versprechen wir Ihnen!",
  "survey.purchase.time-to-complete.subtitle": "Sie können alle Fragen überspringen.",
  
  "survey.purchase.reason": "Was ist der Hauptgrund für den Kauf von screenpolo?",
  "survey.purchase.video-recording": "Ich nehme Videos auf",
  "survey.purchase.screenshot": "Ich mache viele Captures",
  "survey.purchase.support-team": "Ich kaufe immer Software, die ich verwende",
  "survey.purchase.sharing-watermark": "Ich brauche Funktionen zum Teilen oder Wasserzeichen",
  "survey.purchase.support": "Ich möchte vorrangige Unterstützung",
  "survey.purchase.no-update": "Ich möchte Live-Updates deaktivieren",
  "survey.purchase.offline": "Ich möchte screenpolo offline verwenden",
  "survey.purchase.free": "Ich habe es umsonst bekommen",
  "survey.purchase.other": "Anderer Grund",

  // ***********************************************
  // Lost key
  // ***********************************************  
  "lost-key.title": "Sie haben Ihren Softwareschlüssel verloren?",
  "lost-key.sub-title": "Probieren Sie unser automatisches Schlüsseltool aus.",
  "lost-key.email": "Ihre E-Mail-Adresse wird beim Kauf des Aktivierungsschlüssels verwendet",
  "lost-key.submit": "einreichen",
  "lost-key.explanation": "Wenn wir Schlüssel für Bestellungen oder Registrierungen für diese E-Mail finden, senden wir sie an diese Adresse.",
  "lost-key.email-not-found": "Die E-Mail {email} wurde auf unserem Lizenzserver nicht gefunden.",
  "lost-key.email-sent": "Ihr Lizenzschlüssel wurde an Ihre E-Mail-Adresse gesendet {email}.",

  // ***********************************************
  // Form components
  // ***********************************************

  "form.fill-captcha": "Fülle das Captcha",
  "form.missing-recaptcha": "Das Captcha sollte gefüllt sein.",

  // ***********************************************
  // Cloud
  // ***********************************************
  "cloud.title": "screenpolo Cloud",
  "cloud.sub-title": "Eingebaute Freigabe.",
  "cloud.total": "{count, number} {count, plural, one {datei geteilt} other {datein geteilt}} am {date}",
  "cloud.invalid": "Ups, diese Adresse ist nicht verfügbar oder ungültig!",
  "cloud.terms-of-use": "Nutzungsbedingungen",
  "cloud.report-abuse": "Missbrauch melden",
  "cloud.media.dimensions": "Maße",
  "cloud.media.size": "Größe",
};

module.exports = locale;