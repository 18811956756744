import React, { Component, Fragment, useState } from "react";
import Helmet from "react-helmet";

const createAbsUri = (uri) => {
  let ret = uri || "";

  // Add the scheme and hostname for relative uri only
  if (!ret || ret[0] === "/") {
    if (process.env.NODE_ENV !== "development") {
      ret = `https://www.screenpolo.com` + ret;
    } else if (typeof window !== "undefined") {
      ret = window.location.origin + ret;
    }
  }

  return ret;
};

const CustomHelmet = ({ locale, title, locales, featuredImage }) => {
  // Alternate pages
  // https://support.google.com/webmasters/answer/189077?hl=en
  // for instance: <link rel="alternate" hreflang="lang_code" href="url_of_page" />
  const alternates = locales.map((item, index) => ({
    rel: "alternate",
    hrefLang: item.locale,
    href: createAbsUri(item.path),
  }));

  alternates.push({
    rel: "alternate",
    hrefLang: "x-default",
    href: createAbsUri(locales.find(item => item.locale === "en").path),    
  })

  alternates.push({
    rel: "canonical",
    href: createAbsUri(locales.find(item => item.locale === locale).path), 
  })

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
      }}
      title={title}
      link={[
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          content: "/apple-touch-icon.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          content: "/favicon-32x32.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          content: "/favicon-16x16.png",
        },
        { rel: "manifest", href: "/site.webmanifest" },
        {
          rel: "mask-icon",
          href: "/safari-pinned-tab.svg",
          color: "#5bbad5",
        },
        ...alternates,
      ]}
    >
      {/* General tags */}
      <meta
        name="description"
        content="screenpolo screen capture allows you to grab an image or video of what you see on your computer screen, annotate, and share with anyone."
      />
      <meta
        name="keywords"
        content="screenpolo, Screen capture, Screen grabber, image editor, screencast, video capture"
      />
      {/* OpenGraph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content="Grab an image or video of what you see on your computer screen, annotate, and share with anyone."
      />
      <meta property="og:image" content={createAbsUri(featuredImage || "/logo.png")} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@screenpolo" />
      <meta property="twitter:creator" content="@jcbaey" />
    </Helmet>
  );
};

export default CustomHelmet;