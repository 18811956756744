const locale = {
  // ***********************************************
  // URLs
  // ***********************************************
  "page.index.url": "",
  "page.index.name": "Accueil",
  "page.index.title":
    "Screenpolo : l'outil de capture d'écran pour la productivité",

  "page.features.url": "fonctionnalites",
  "page.features.name": "Fonctionnalités",
  "page.features.title": "Fonctionnalités de screenpolo",

  "page.pricing.url": "tarifs",
  "page.pricing.name": "Tarifs",
  "page.pricing.title": "Tarifs",

  "page.upgrade.url": "renouvellement",
  "page.upgrade.name": "Renouvellement",
  "page.upgrade.title": "Mettre à niveau vers la dernière version",

  "page.volume-discount.url": "reductions-achat-en-volume",
  "page.volume-discount.name": "Réductions pour achat en volume",
  "page.volume-discount.title": "Réductions pour achat en volume",

  "page.download.url": "telecharger",
  "page.download.name": "Télécharger",
  "page.download.title": "Télécharger screenpolo",

  "page.downloading.url": "telechargement-exe",
  "page.downloading.name": "Télécharger screenpolo.exe",
  "page.downloading.title": "Téléchargement en cours...",

  "page.downloading-msi.url": "telechargement-msi",
  "page.downloading-msi.name": "Télécharger le MSI...",
  "page.downloading-msi.title": "Téléchargement du msi en cours...",

  "page.company.url": "a-propos",
  "page.company.name": "A Propos",
  "page.company.title": "A Propos de screenpolo",

  "page.support.url": "support",
  "page.support.name": "Support",
  "page.support.title": "Aide en ligne",

  "page.privacy-policy.url": "politique-de-confidentialite",
  "page.privacy-policy.name": "Politique de confidentialité",
  "page.privacy-policy.title": "Politique de confidentialité",

  "page.affiliate-program.url": "affiliation",
  "page.affiliate-program.name": "Programme d'affiliation",
  "page.affiliate-program.title": "Programme d'affiliation",

  "page.releases.url": "versions",
  "page.releases.name": "Versions",
  "page.releases.title": "Versions de screenpolo",

  "page.news.url": "nouvelles",
  "page.news.name": "Quoi de neuf?",
  "page.news.title": "Screenpolo: Quoi de neuf?",

  "page.third-parties.url": "logiciels-tiers-inclus",
  "page.third-parties.name": "Logiciel tiers",
  "page.third-parties.title": "Logiciel tiers utilisé par screenpolo",

  "page.software-license-agreement.url": "contrat-de-licence",
  "page.software-license-agreement.name": "Contrat de licence",
  "page.software-license-agreement.title": "Contrat de licence",

  "page.translation.url": "traduction",
  "page.translation.name": "Traduction",
  "page.translation.title": "A propos des traducteurs",

  "page.install-survey.url": "sondage-installation",
  "page.install-survey.name": "Sondage après installation",
  "page.install-survey.title": "Sondage après installation de screenpolo",

  "page.uninstall-survey.url": "sondage-desinstallation",
  "page.uninstall-survey.name": "Sondage après désinstallation",
  "page.uninstall-survey.title": "Sondage après désinstallation de screenpolo",

  "page.purchase-survey.url": "sondage-apres-achat",
  "page.purchase-survey.name": "Sondage après achat",
  "page.purchase-survey.title": "Sondage après achat de screenpolo",

  "page.thank-you.url": "merci",
  "page.thank-you.name": "Merci",
  "page.thank-you.title": "Merci",
  
  "page.lost-key.url": "cle-de-licence-perdue",
  "page.lost-key.name": "Retrouver votre clé logiciel",
  "page.lost-key.title": "Vous avez perdu votre clé de licence ?",

  "page.reseller.url": "revendeur",
  "page.reseller.name": "Revendeur",
  "page.reseller.title": "Boutique revendeur",

  "page.cloud.url": "cloud",
  "page.cloud.name": "Cloud",
  "page.cloud.title": "screenpolo Cloud",

  // ***********************************************
  // Header and footer
  // ***********************************************

  "top-menu.languages": "Langages",

  "top-menu.support.license-section": "Licence",
  "top-menu.support.enterprise-section": "Entreprise",
  "top-menu.support.support-section": "Aide en ligne",
  "top-menu.support.help-center-entry": "Centre d'aide",


  "footer.site": "Site",
  "footer.contact-us": "Nous contacter",
  "footer.social-networks": "Réseaux sociaux",
  "footer.recent-releases": "Dernières versions",
  "footer.languages": "Langages",
  "footer.copyrights": "Tous droits réservés",

  "footer.subscribe-newsletter.title": "Abonnez-vous à notre newsletter",
  "footer.subscribe-newsletter.description": "Les dernières nouvelles et communiqués, envoyés chaque semaine dans votre boîte de réception.",
  "footer.subscribe-newsletter.email": "Adresse e-mail",
  "footer.subscribe-newsletter.enter-email": "Entrer votre Email",
  "footer.subscribe-newsletter.subscribe": "Souscrire",
  "footer.subscribe-newsletter.email-added": "Votre email a été ajouté!",

  // ***********************************************
  // Home
  // ***********************************************

  "index.title": "Capture d’écran en image et vidéo",
  "index.sub-title":
    "Gagnez du temps en expliquant visuellement avec des captures d’écran",
  "index.push-line": "Une image vaut mille mots!",
  "index.description.part-1":
    "<strong>screenpolo</strong> capture votre bureau Windows (<strong>en images et en vidéos HD</strong>) pour vos documents, tutoriels, maquettes, travaux collaboratifs, vos rapports de bogues et bien plus…",
  "index.description.part-2":
    "screenpolo est un outil léger de <strong>capture d’écran</strong> avec <strong>éditeur intégré</strong>, générateur de documents et fonctionnalités de partage.",
  "index.main-button.text": "Télécharger screenpolo GRATUITEMENT",
  "index.main-button.sub-text": "Toutes les versions de Windows",
  "index.more-features-button": "Découvrir toutes les fonctionnalités...",
  "index.feature-highlight.capture.title": "Capturez en Vidéos et Images",
  "index.feature-highlight.capture.content":
    "<strong>Capturez ce que vous voyez sur votre écran</strong> en quelques clics.{br}{br}Organisez votre <strong>historique de captures</strong> par espaces de travail.",

  "index.feature-highlight.editor.title":
    "Créez de superbes images et documents",
  "index.feature-highlight.editor.content":
    "Editer et <strong>mettez en avant ce qui est important dans les images</strong> avec notre <strong>éditeur d’images intégré</strong>.{br}{br}Créez des documents PDF avec notre <strong>générateur de documents</strong>.",

  "index.feature-highlight.sharing.title": "Partagez vos captures d’écran",
  "index.feature-highlight.sharing.content":
    "Glissez-déposez vos captures d’écran sur vos e-mails pour <strong>créer des fichiers attachés en 1 seconde</strong>.{br}{br}Partagez vos vidéos et images avec <strong>screenpolo Cloud sans créer de compte</strong>{br}{br}Publiez sur <strong>Evernote, Google Drive, Twitter, Facebook, Dropbox</strong> et bien plus…",

  "index.testimonials.title": "Nos clients parlent de screenpolo",
  "index.referrals.title": "Les plus grosses entreprises utilisent screenpolo",
  "index.download-macos-version": "Téléchargez votre version MacOs",

  // ***********************************************
  // Features
  // ***********************************************

  "features.title": "Fonctionnalités",
  "features.pre-title": "Prenez le contrôle de vos captures.",
  "features.post-title": "Jetez un œil à notre visite guidée.",

  // ***********************************************
  // Download
  // ***********************************************

  
  "download.title": "Téléchargez la NOUVELLE version GRATUITEMENT",
  "download.pre-title": "Téléchargez la NOUVELLE version",
  "download.post-title": "gratuitement",
  "download.download": "Télécharger",

  "download.latest-version": "Nouvelle version ({version})",
  "download.release-note": "Notes de version",
  "download.user-manual": "Manuel utilisateur",
  "download.not-supported": "MacOS, iOS et Android ne sont pas supportés",
  "download.os-types": "32 et 64 bits",
  "download.languages.main": "Anglais, Français, Espagnol, Allemand et {more}",
  "download.languages.more": "15 de plus",
  "download.languages.rest":
    "Japonais, Russe, Chinois simplifié, Chinois traditionnel, Turc, Georgien, Portuguais, Brésilien, Arabe, Italien, Ukrainien, Polonais, Danois, Hongrois, Flamand",
  "download.exe-prerequisites": "{dotnet} ou suppérieur est requis",
  "download.exe-button.text": "Télécharger screenpolo ({size})",
  "download.exe-button.sub-text": "Application portable avec programme d'installation inclus",
  "download.msi-version": "Déploiement en entreprise ({version})",
  "download.msi-description":
    "Solution tout en un pour installer screenpolo dans les sociétés.",
  "download.msi-prerequisites":
    "{dotnet} et les drois administrateurs sont requis.",
  "download.msi-button.text": "Télécharger le package d'installation  msi ({size})",
  "download.msi-button.sub-text": "pour le déploiement en entreprise",

  // ***********************************************
  // Release note
  // ***********************************************
  
  "release.whatsnew": "Quoi de neuf?",
  "release.tags.latest": "NOUVEAU",
  "release.previous-release": "Version précédente",
  "release.latest-release": "Dernière version",
  "release.menu.older-releases": "Voir les anciennes versions",
  "release.menu.all-releases": "Voir toutes les versions",
  "release.download-button": "Télécharger cette version",
  "release.new-version-warning.header": "Une nouvelle version est disponible",
  "release.new-version-warning.content":
  "Cliquez ici pour voir la dernière version ({version})",
  "release.update.label": "Lancez la vérification des mises à jour",
  "release.how-to-upgrade": "Comment mettre à jour?",
  "release.update.alternative": "ou {checkForUpdate} pour avoir la mise à jour gratuite",

  // ***********************************************
  // Blog
  // ***********************************************
  
  "blog.read-article": "Voir l'article complet",
  

  // ***********************************************
  // Pricing
  // ***********************************************
  "pricing.title": "Découvrez comment screenpolo peut améliorer votre productivité.",
  "pricing.sub-title": "Utilisé par plus de 1,000,000 d’utilisateurs.",
  "pricing.faq": "Questions fréquentes",

  "pricing.plan.base": "BASE",
  "pricing.plan.from":
    "Toutes les fonctionnalités de screenpolo {version} sont incluses",
  "pricing.tax": "(incl. {tax} de TVA)",
  "pricing.black-friday": "<p>50% de réduction avec le code <strong>BLACK_FRIDAY_2019</strong> sur screenpolo PRO</p>",

  // Free Plan
  // -----------
  "pricing.free-plan.product": "GRATUIT",
  "pricing.free-plan.recommended-for": "100% GRATUIT",
  "pricing.free-plan.sub-title": "mises à jour{br}obligatoires",
  "pricing.free-plan.price": "GRATUIT",
  "pricing.free-plan.features.image-capture": "Capture en images",
  "pricing.free-plan.features.video-capture": "Capture en vidéos HD",
  "pricing.free-plan.features.branding": "avec logo incrusté",
  "pricing.free-plan.features.limited-editor": "Editeur d’images limité",
  "pricing.free-plan.features.at-work": "Utilisation en entreprise",

  // Free Plan - action button
  "pricing.free-plan.action.get": "Télécharger votre version gratuite",

  // Pro Plan
  // -----------
  "pricing.pro-plan.product": "PRO",
  "pricing.pro-plan.recommended-for": "INDEPENDANTS OU PETITE EQUIPES",
  "pricing.pro-plan.sub-title":
    "par utilisateur{br}<strong>licence perpétuelle</strong>",

  // Pro Plan - capture section
  "pricing.pro-plan.features.title.capture": "CAPTURER",
  "pricing.pro-plan.features.video-capture": "Capture en vidéos HD",
  "pricing.pro-plan.features.video-capture-details":
    "inclus l’enregistrement du son système",
  "pricing.pro-plan.features.android-capture": "Capture Android",
  "pricing.pro-plan.features.text-capture": "Capture du texte des images (OCR)",
  "pricing.pro-plan.features.color-picker": "Pipette de couleur",

  // Pro Plan - edit, organize and share section
  "pricing.pro-plan.features.title.edit-organize": "EDITER, CLASSER & PARTAGER",
  "pricing.pro-plan.features.editor": "Editeur d’images complet",
  "pricing.pro-plan.features.editor-details":
    "réédition, effets et watermark",
  "pricing.pro-plan.features.doc-generator": "Générateur de documents",
  "pricing.pro-plan.features.video-editor": "Découpage vidéo et fusion",
  "pricing.pro-plan.features.video-editor-details": "(début et fin)",
  "pricing.pro-plan.features.workspaces": "Plusieurs espaces de travail",
  "pricing.pro-plan.features.sharing": "Fonctions de partage",

  // Pro Plan - pro section
  "pricing.pro-plan.features.title.pro": "PRO",
  "pricing.pro-plan.features.support": "Support prioritaire (pendant 1 an)",
  "pricing.pro-plan.features.policies": "Polices",
  "pricing.pro-plan.features.policies-details":
    "(permet aux administrateurs de désactiver des fonctions)",

  // Pro Plan - action button
  "pricing.pro-plan.action.buy": "Acheter MAINTENANT",
  "pricing.pro-plan.action.guarantee": "Satisfait ou remboursé (30 jours)",

  // Site Plan
  // -----------
  "pricing.site-plan.product": "Licence en volume",
  "pricing.site-plan.recommended-for": "ENTREPRISES",
  "pricing.site-plan.sub-title":
    "<strong>utilisateurs illimités</strong>{br}licence perpétuelle",

  "pricing.site-plan.discount": "{price} jusqu'au 31/05/2019",

  // Site Plan - enterprise section
  "pricing.site-plan.features.title.enterprise": "ENTREPRISES",
  "pricing.site-plan.features.virtualization":
    "Requis sous Citrix, TSM, VMWare VDI",
  "pricing.site-plan.features.virtualization-details":
    "ou déploiement massif",
  "pricing.site-plan.features.no-internet": "Pas de connexion internet requise",
  "pricing.site-plan.features.simple-activation": "Activation de la licence simplifiée",

  // Site Plan - action button
  "pricing.site-plan.action.contact-us": "Nous contacter",
  "pricing.site-plan.action.purpose": "Pour les devis et bons de commande",

  // ***********************************************
  // Volume discount
  // ***********************************************
  "volume-discount.title": "Tarifs dégressifs",
  "volume-discount.sub-title":
    "Partager la version complète de screenpolo avec toute votre équipe.",
  "volume-discount.quantity": "Quantité",
  "volume-discount.price": "Prix",
  "volume-discount.buy": "Acheter",
  "volume-discount.license-pack":
    "Pack: {count, number} {count, plural, one {licence} other {licences}}",
  "volume-discount.buy-for":
    "Acheter pour {count, number} {count, plural, one {utilisateur} other {utilisateurs}}",
  "volume-discount.price-per-unit": "{price}",
  "volume-discount.discount": "{percentage} de réduction",
  "volume-discount.save": "Economisez {value}",
  "volume-discount.enter-quantity": "Votre nombre d'utilisateurs",
  "volume-discount.site-license": "Site licence : utilisateurs illimités",
  "volume-discount.unlimited": "Acheter pour tous les utilisateurs",

  // ***********************************************
  // Upgrade
  // ***********************************************
  "upgrade.title": "Mise à jour de screenpolo vers la dernière version.",
  "upgrade.sub-title": "Renouvelez pour obtenir de nouvelles fonctionnalités et mises à jour pendant 1 an de plus.",
  "upgrade.enter-your-key": "Saisissez votre clé de licence pour connaître votre prix",
  "upgrade.lost-your-key": "Vous avez perdu votre clé de licence?",
  "upgrade.buy-new-one": "Nouveau client? Acheter une licence",
  "upgrade.validate": "Valider votre clé",
  "upgrade.upgrade-plan": "Mise à niveau",
  "upgrade.renewal-price": "Prix pour le renouvellement",
  "upgrade.invalid-key": "Votre clé n'est pas valide",
  "upgrade.pro": "Mise à niveau de {name} pour {price}",
  "upgrade.tax": "(incl. {tax} de TVA)",
  "upgrade.volume-discount": "les remises sur volume s'appliquent automatiquement.",

  // ***********************************************
  // Reseller
  // ***********************************************
  "reseller.title": "Boutique revendeur",
  "reseller.sub-title":
    "Achetez screenpolo pour vos clients.",
  "reseller.product-selection": "Sélectionnez le produit à commander",
  "reseller.request-reseller-company": "Le nom de votre entreprise (facturation)",
  "reseller.request-reseller-email": "Votre email (facturation)",
  "reseller.request-end-user-company": "Le nom de l'entreprise du client final (pour la clé de licence)",
  "reseller.request-end-user-email": "L'email du client final (pour la clé de licence)",

  "reseller.missing-product": "Un produit n'est pas sélectionné",
  "reseller.missing-product-quantity": "La quantité est vide",
  "reseller.missing-reseller-company": "La société revendeur est vide",
  "reseller.invalid-missing-reseller-email": "L'email du revendeur est vide ou invalide",
  "reseller.missing-end-user-company-name": "La société de l'utilisateur final est vide",
  "reseller.invalid-missing-end-user-email": "Le courrier électronique de l'utilisateur final est vide ou non valide",

  "reseller.regular-price": "Prix régulier: {value} (TTC)",
  "reseller.discount": "Remise revendeur (y compris remise sur volume): {value}",
  "reseller.total": "Total: {value} (dont taxes: {tax})",
  "reseller.user-quantity": "Nombre d'utilisateurs finaux",

  // ***********************************************
  // Support
  // ***********************************************
  "support.title": "F.A.Q.",
  "support.sub-title": "Foire aux questions",
  "support.categories.installation": "Installation",
  "support.categories.editor": "Editeur intégré",
  "support.categories.licensing": "Licence",
  "support.categories.others": "Autres",
  "support.categories.screenshot": "Capture d'image",
  "support.categories.video-capture": "Capture vidéo",
  "support.categories.troubleshooting": "Dépannage",
  "support.categories.upgrade": "Mises à jour",
  "support.see-also": "Voir aussi...",

  // ***********************************************
  // Company / About us
  // ***********************************************
  "company.title": "screenpolo est édité par Learnpulse SAS",
  "company.pre-title":
    "Nous fabriquons à la main des solutions de capture d'écran",
  "company.post-title":
    "Fabriqué à Toulouse",
  "company.who-are-we.title": "Qui sommes nous ?",
  "company.who-are-we.content.part1":
    "<strong>screenpolo</strong> est édité par <strong>LEARNPULSE SAS</strong>, une société créée en 2012.",
  "company.who-are-we.content.part2":
    "{bjamin} (Directeur, sur la gauche) and {jcbaey} (Président, sur la droite, {twitter_jc})  sont les co-fondateurs de Learnpulse SAS. ils ont conçu et développé screenpolo avec le soucis du détail pour avoir un outil de capture d’écran ergonomique et orienté productivité grâce à leurs expériences respectives dans de grandes entreprises.",
  "company.who-are-we.content.part3":
    "Learnpulse SAS fait partie du programme startup de Microsoft {link}",
  "company.contact-us": "Nous contacter",
  "company.email.title": "E-mail",
  "company.email.content":
    "Pour l’assistance technique, le support client, commercial, presse ou pour les investisseurs:",
  "company.postal-address": "Adresse postale",
  "company.social-medias": "Réseaux sociaux",
  "company.legal-information": "Informations légales",
  "company.company-type": "(Société à Actions Simplifiées)",
  "company.registration-number": "KBIS",
  "company.capital": "Capital",
  "company.headquarters": "Siège social",
  "company.siret": "SIRET",
  "company.vat": "Numéro d’identification de TVA (VAT ID)",

  // ***********************************************
  // Downloading
  // ***********************************************

  "downloading.title": "Téléchargement en cours...",
  "downloading.sub-title": "Le téléchargement va démarrer dans quelques secondes...",
  "downloading.content.title": "Merci de télécharger screenpolo !",
  "downloading.content.text":
    "Si le téléchargement ne démarre pas, cliquez sur le {link}.",
  "downloading.content.release-btn": "Voir notre release note",
  "downloading.link": "lien de téléchargement direct",
  "downloading.link-title": "Cliquez ici pour télécharger",
  "subscribe-modal.title": "Ne ratez pas nos nouvelles versions !",
  "subscribe-modal.placeholder": "votre e-mail",
  "subscribe-modal.text":
    "Nous vous enverrons quelques emails pour démarrer. Desinscription possible à tout moment.",
  "subscribe-modal.skip": "Passer",
  "subscribe-modal.invalid-email": "Votre email est manquant ou invalide",

  "releases.title": "Versions",
  "releases.sub-title": "Toutes les versions de screenpolo",

  // ***********************************************
  // Components
  // ***********************************************

  "release-image.new": "NOUVELLE",
  "release-image.version": "VERSION DISPONIBLE",

  // ***********************************************
  // Installation survey
  // ***********************************************
  "survey.install.title": "Sondage après installation de screenpolo",
  "survey.install.sub-title":
    "Aidez-nous à mieux vous connaître avec quelques questions!",
  "survey.install.time-to-complete":
    "Seulement 30 secondes de votre temps, nous vous le promettons!",
  "survey.install.time-to-complete.subtitle": "Vous pouvez ignorer des questions.",

  "survey.features": "Quelles sont les fonctionnalités les plus utilisées de screenpolo?",
  "survey.features.screenshot": "Capture d'image (capture d'écran)",
  "survey.features.screencast": "Capture vidéo (screencast)",
  "survey.features.img-editor": "Éditeur d'image",
  "survey.features.scrolling-capture": "Capture des fenêtre avec défilement",
  "survey.features.sharing": "Partage",

  "survey.where": "Où utilisez-vous screenpolo?",
  "survey.where.home": "À la maison",
  "survey.where.work": "Au travail",

  "survey.installation-count":
    "Sur combien d'ordinateurs avez-vous installé screenpolo?",

  "survey.age": "Votre âge",

  "survey.from": "Où avez-vous entendu parler de screenpolo? Commentaire libre",
  "survey.tell-us-more": "Parlez-nous de vous ...",

  "survey.email": "Votre email pour que nous puissions vous répondre!",

  "survey.social": "N'oubliez pas de nous suivre sur les réseaux sociaux",

  // ***********************************************
  // Survey components
  // ***********************************************
  "survey.submit": "Soumettre mes réponses",

  "survey.jobs": "Votre travail, domaine d'activité",
  "survey.jobs.architecture": "Architecture",
  "survey.jobs.cad": "CAO",
  "survey.jobs.education": "Education",
  "survey.jobs.graphics": "Graphique",
  "survey.jobs.management": "Gestion",
  "survey.jobs.sw": "Développement de logiciels",
  "survey.jobs.support": "Support",
  "survey.jobs.test": "Test",
  "survey.jobs.other": "Autre",

  // ***********************************************
  // Uninstallation survey
  // ***********************************************
  "survey.uninstall.title": "Enquête de désinstallation",
  "survey.uninstall.sub-title":
    "Votre avis est essentiel pour nous! Veuillez nous dire pourquoi vous avez désinstallé screenpolo.",
  "survey.uninstall.time-to-complete":
    "Seulement 30 secondes de votre temps, nous vous le promettons!",
  "survey.uninstall.time-to-complete.subtitle": "Vous pouvez ignorer des questions.",

  "survey.uninstall.reason": "Pourquoi avez-vous désinstallé screenpolo?",
  "survey.uninstall.reason.other": "Autres raisons",
  "survey.uninstall.reason.re-install": "Je vais l'installer sur un autre ordinateur",
  "survey.uninstall.reason.new-version": "Je veux mettre à jour vers la dernière version",
  "survey.uninstall.reason.missing-feature": "Une fonctionnalité importante est manquante",
  "survey.uninstall.reason.alternatives": "Il y a de meilleures alternatives",
  "survey.uninstall.reason.cpu-issue": "Il utilise trop de ressources CPU ou est trop lent",
  "survey.uninstall.reason.unstable": "Il n'est pas assez stable",
  "survey.uninstall.reason.antivirus-issue": "Mon pare-feu ou mon antivirus m'empêche de l'utiliser",
  "survey.uninstall.reason.not-used": "Je ne l'utilise plus",

  "survey.uninstall-details": "Quelle fonctionnalité est manquante? Quelle erreur s'est produite? Qu'est-ce qui devrait être changé? (commentaires libres)",
  "survey.uninstall.email": "Votre email pour que nous puissions vous répondre!",

  "survey.uninstall.missing-reason": "Veuillez indiquer pourquoi vous avez désinstallé",

  // ***********************************************
  // Thank you page
  // ***********************************************  
  "thank-you.title": "Nous vous remercions!",
  "thank-you.sub-title": "Merci beaucoup pour votre temps et vos réponses.",
  "thank-you.support": "N'oubliez pas de regarder nos tutoriels",
  "thank-you.support.button": "Afficher plus...",

  // ***********************************************
  // Purchase survey
  // ***********************************************
  "survey.purchase.title": "Enquête d'achat",
  "survey.purchase.sub-title":
    "Aidez-nous à mieux vous connaître avec quelques questions!",
  "survey.purchase.time-to-complete":
    "Seulement 30 secondes de votre temps, nous vous le promettons!",
  "survey.purchase.time-to-complete.subtitle": "Vous pouvez ignorer des questions.",
  
  "survey.purchase.reason": "Quelles sont les principales raisons pour lesquelles vous achetez screenpolo?",
  "survey.purchase.video-recording": "J'enregistre des vidéos",
  "survey.purchase.screenshot": "Je fais beaucoup de captures",
  "survey.purchase.support-team": "J'achète toujours un logiciel que j'utilise",
  "survey.purchase.sharing-watermark": "J'ai besoin de fonctions de partage ou de filigrane",
  "survey.purchase.support": "Je veux un support prioritaire",
  "survey.purchase.no-update": "Je veux désactiver les mises à jour",
  "survey.purchase.offline": "Je veux utiliser screenpolo hors ligne",
  "survey.purchase.free": "Je l'ai eu gratuitement",
  "survey.purchase.other": "Autre raison",

  // ***********************************************
  // Lost key
  // ***********************************************  
  "lost-key.title": "Vous avez perdu votre clé de licence?",
  "lost-key.sub-title": "Essayez notre outil de recherche de clé automatique.",
  "lost-key.email": "Votre adresse e-mail utilisée lors de l'achat de la licence",
  "lost-key.submit": "Soumettre",
  "lost-key.explanation": "Si nous trouvons les clés des commandes ou des enregistrements pour cet e-mail, nous les enverrons à cette adresse.",
  "lost-key.email-not-found": "L'email {email} n'est pas trouvé sur notre serveur de licences.",
  "lost-key.email-sent": "Votre clé de licence a été envoyée à votre adresse e-mail {email}.",

  // ***********************************************
  // Form components
  // ***********************************************

  "form.fill-captcha": "Remplir le captcha",
  "form.missing-recaptcha": "Le captcha doit être rempli.",

  // ***********************************************
  // Cloud
  // ***********************************************
  "cloud.title": "screenpolo cloud",
  "cloud.sub-title": "Partage intégré.",
  "cloud.total": "{count, number} {count, plural, one {media partagé} other {medias partagés}} le {date}",
  "cloud.invalid": "Oups, cette adresse n'est plus disponible ou n'est pas valide!",
  "cloud.terms-of-use": "Conditions d'utilisation",
  "cloud.report-abuse": "Signaler un abus",
  "cloud.media.dimensions": "Dimensions",
  "cloud.media.size": "Taille",
};

module.exports = locale;