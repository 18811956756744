const locale = {
  // ***********************************************
  // URLs
  // ***********************************************
  "page.index.url": "",
  "page.index.name": "Home",
  "page.index.title":
    "Screenpolo: the screen capture tool for productivity",

  "page.features.url": "features",
  "page.features.name": "Features",
  "page.features.title": "Features of screenpolo",

  "page.pricing.url": "pricing",
  "page.pricing.name": "Pricing",
  "page.pricing.title": "screenpolo pricing",

  "page.upgrade.url": "upgrade",
  "page.upgrade.name": "Upgrade",
  "page.upgrade.title": "Upgrade to the latest version",

  "page.volume-discount.url": "volume-discount",
  "page.volume-discount.name": "Volume discount",
  "page.volume-discount.title": "Volume discount",

  "page.download.url": "download",
  "page.download.name": "Download",
  "page.download.title": "Download screenpolo",

  "page.downloading.url": "downloading-exe",
  "page.downloading.name": "Download of screenpolo.exe",
  "page.downloading.title": "Downloading...",

  "page.downloading-msi.url": "downloading-msi",
  "page.downloading-msi.name": "Download of the msi",
  "page.downloading-msi.title": "Downloading the MSI...",

  "page.company.url": "about-us",
  "page.company.name": "About Us",
  "page.company.title": "About Us",

  "page.support.url": "support",
  "page.support.name": "Support",
  "page.support.title": "Help center",

  "page.privacy-policy.url": "privacy-policy",
  "page.privacy-policy.name": "Privacy Policy",
  "page.privacy-policy.title": "Privacy Policy",

  "page.affiliate-program.url": "affiliate-program",
  "page.affiliate-program.name": "Affiliate Program",
  "page.affiliate-program.title": "Affiliate Program",

  "page.releases.url": "releases",
  "page.releases.name": "Releases",
  "page.releases.title": "Screenpolo releases",

  "page.news.url": "news",
  "page.news.name": "What's new?",
  "page.news.title": "Screenpolo: What's new?",

  "page.third-parties.url": "third-parties",
  "page.third-parties.name": "Third parties",
  "page.third-parties.title": "Third parties",

  "page.software-license-agreement.url": "software-license-agreement",
  "page.software-license-agreement.name": "Software License Agreement",
  "page.software-license-agreement.title": "Software License Agreement",

  "page.translation.url": "translation",
  "page.translation.name": "Translation",
  "page.translation.title": "About translators",

  "page.install-survey.url": "install-survey",
  "page.install-survey.name": "Installation survey",
  "page.install-survey.title": "Installation survey of screenpolo",

  "page.uninstall-survey.url": "uninstall-survey",
  "page.uninstall-survey.name": "Exit survey",
  "page.uninstall-survey.title": "Uninstallation/exit survey of screenpolo",

  "page.purchase-survey.url": "purchase-survey",
  "page.purchase-survey.name": "Purchase survey",
  "page.purchase-survey.title": "After purchase survey",

  "page.thank-you.url": "thank-you",
  "page.thank-you.name": "Thank You",
  "page.thank-you.title": "Thank You",
  
  "page.lost-key.url": "lost-key",
  "page.lost-key.name": "Find Software key",
  "page.lost-key.title": "Lost Your Software Key?",

  "page.reseller.url": "reseller-store",
  "page.reseller.name": "Reseller store",
  "page.reseller.title": "Reseller specific store",

  "page.cloud.url": "cloud",
  "page.cloud.name": "Cloud",
  "page.cloud.title": "screenpolo Cloud",

  // ***********************************************
  // Header and footer
  // ***********************************************

  "top-menu.languages": "Languages",

  "top-menu.support.license-section": "License",
  "top-menu.support.enterprise-section": "Enterprise",
  "top-menu.support.support-section": "Support",
  "top-menu.support.help-center-entry": "Help center",


  "footer.site": "Site",
  "footer.contact-us": "Contact us",
  "footer.social-networks": "Social networks",
  "footer.recent-releases": "Recent releases",
  "footer.languages": "Languages",
  "footer.copyrights": "All Rights Reserved",

  "footer.subscribe-newsletter.title": "Subscribe to our newsletter",
  "footer.subscribe-newsletter.description": "The latest news and releases, sent to your inbox weekly.",
  "footer.subscribe-newsletter.email": "Email address",
  "footer.subscribe-newsletter.enter-email": "Enter your email",
  "footer.subscribe-newsletter.subscribe": "Subscribe",
  "footer.subscribe-newsletter.email-added": "Your email has been added!",

  // ***********************************************
  // Home
  // ***********************************************

  "index.title": "Image and Video screen capture",
  "index.sub-title":
    "Time saver for explaining something to your colleagues and clients",
  "index.push-line": "A screenshot is worth a thousand words!",
  "index.description.part-1":
    "<strong>screenpolo</strong> captures your desktop (<strong>screenshots and HD videos</strong>) for your training documents, collaborative design work, IT bug reports, and more…",
  "index.description.part-2":
    "screenpolo is a NEW Light-weight <strong>screen grab tool</strong> with built-in <strong>image editor</strong>, user guide generator and sharing options.",
  "index.main-button.text": "Get screenpolo for FREE",
  "index.main-button.sub-text": "All Windows versions",
  "index.more-features-button": "Discover more features...",
  "index.feature-highlight.capture.title": "Capture in Videos or Images",
  "index.feature-highlight.capture.content":
    "<strong>Capture what you see on your screen</strong> with just a few clicks.{br}{br}Manage your <strong>capture history</strong> by workspaces.",

  "index.feature-highlight.editor.title":
    "Create great looking images and documents",
  "index.feature-highlight.editor.content":
    "Edit and <strong>highlight what matter in your screenshots</strong> with our <strong>built-in image editor</strong>.{br}{br}Create PDF documents with our <strong>document generator</strong>.",

  "index.feature-highlight.sharing.title": "Share your screen captures",
  "index.feature-highlight.sharing.content":
    "Drag & drop your captures to your emails to <strong>create attachments in 1 second</strong>.{br}{br}Share videos and images with <strong>screenpolo Cloud without registration</strong>.{br}{br}Publish to <strong>Evernote, Google Drive, Twitter, Facebook, Dropbox</strong> and more…",

  "index.testimonials.title": "Our Customers Say",
  "index.referrals.title": "Daily used by large companies",
  "index.download-macos-version": "Download your MacOs version",

  // ***********************************************
  // Features
  // ***********************************************

  "features.title": "Features",
  "features.pre-title": "Take the control of your captures.",
  "features.post-title": "Have a look to our feature tour.",

  // ***********************************************
  // Download
  // ***********************************************

  
  "download.title": "Download",
  "download.pre-title": "Download the NEW version",
  "download.post-title": " for FREE",
  "download.download": "Download",

  "download.latest-version": "Latest version ({version})",
  "download.release-note": "Release note",
  "download.user-manual": "User manual",
  "download.not-supported": "MacOS, iOS and Android are not supported",
  "download.os-types": "32 and 64 bits",
  "download.languages.main": "English, Spanish, French, German and {more}",
  "download.languages.more": "15 more",
  "download.languages.rest":
    "Italian, Simplified Chinese, Traditional Chinese, Turkish, Georgian, Russian, Ukrainian, Brazilian, Portuguese, Japanese, Arabic, Polish, Danish, Dutch",
  "download.exe-prerequisites": "{dotnet} or above is required",
  "download.exe-button.text": "Download screenpolo ({size})",
  "download.exe-button.sub-text": "Portable application with setup included",
  "download.msi-version": "Enterprise deployment ({version})",
  "download.msi-description":
    "All in one package for installing screenpolo in companies.",
  "download.msi-prerequisites":
    "{dotnet} and administrator privileges are required.",
  "download.msi-button.text": "Download Setup msi ({size})",
  "download.msi-button.sub-text": "Preferred for company deployment",

  // ***********************************************
  // Release note
  // ***********************************************
  
  "release.whatsnew": "What's new?",
  "release.tags.latest": "LATEST",
  "release.previous-release": "Previous release",
  "release.latest-release": "Latest release",
  "release.menu.older-releases": "View older releases",
  "release.menu.all-releases": "View all releases",
  "release.download-button": "Download this version",
  "release.new-version-warning.header": "A new version is available",
  "release.new-version-warning.content":
  "Click here to see the latest version ({version})",
  "release.update.label": "check for update",
  "release.how-to-upgrade": "How to upgrade?",
  "release.update.alternative": "or {checkForUpdate} to get the FREE update",

  // ***********************************************
  // Blog
  // ***********************************************
  
  "blog.read-article": "Read full story",
  

  // ***********************************************
  // Pricing
  // ***********************************************
  "pricing.title": "Discover how screenpolo can increase your productivity.",
  "pricing.sub-title": "Trusted by more than 1,000,000 active users.",
  "pricing.faq": "F.A.Q.",

  "pricing.plan.base": "BASE",
  "pricing.plan.from":
    "All the features of screenpolo {version} are included",
  "pricing.tax": "(incl. {tax} tax)",
  "pricing.black-friday": "<p>50% discount with the code <strong>BLACK_FRIDAY_2019</strong> on screenpolo PRO</p>",

  // Free Plan
  // -----------
  "pricing.free-plan.product": "FREE",
  "pricing.free-plan.recommended-for": "100% FREE",
  "pricing.free-plan.sub-title": "updates{br}are mandatory",
  "pricing.free-plan.price": "FREE",
  "pricing.free-plan.features.image-capture": "Image capture",
  "pricing.free-plan.features.video-capture": "HD Video capture",
  "pricing.free-plan.features.branding": "with branding",
  "pricing.free-plan.features.limited-editor": "Limited image editor",
  "pricing.free-plan.features.at-work": "Usage at work",

  // Free Plan - action button
  "pricing.free-plan.action.get": "Get your Free version",

  // Pro Plan
  // -----------
  "pricing.pro-plan.product": "PRO",
  "pricing.pro-plan.recommended-for": "FREELANCERS AND SMALL TEAMS",
  "pricing.pro-plan.sub-title":
    "per user{br}<strong>one time purchase</strong>",

  // Pro Plan - capture section
  "pricing.pro-plan.features.title.capture": "CAPTURE",
  "pricing.pro-plan.features.video-capture": "HD Video capture",
  "pricing.pro-plan.features.video-capture-details":
    "including system sound recording",
  "pricing.pro-plan.features.android-capture": "Android capture",
  "pricing.pro-plan.features.text-capture": "Text capture from images (OCR)",
  "pricing.pro-plan.features.color-picker": "Color Picker",

  // Pro Plan - edit, organize and share section
  "pricing.pro-plan.features.title.edit-organize": "EDIT, ORGANIZE & SHARE",
  "pricing.pro-plan.features.editor": "Full featured image editor",
  "pricing.pro-plan.features.editor-details":
    "including rework, effects and watermarking",
  "pricing.pro-plan.features.doc-generator": "Document generator",
  "pricing.pro-plan.features.video-editor": "Video clipping and merge",
  "pricing.pro-plan.features.video-editor-details": "(start and end)",
  "pricing.pro-plan.features.workspaces": "Multiple workspaces",
  "pricing.pro-plan.features.sharing": "Sharing features",

  // Pro Plan - pro section
  "pricing.pro-plan.features.title.pro": "PRO",
  "pricing.pro-plan.features.support": "Priority Support (1 year)",
  "pricing.pro-plan.features.policies": "Policies",
  "pricing.pro-plan.features.policies-details":
    "(for administrators to disable some features)",

  // Pro Plan - action button
  "pricing.pro-plan.action.buy": "Buy NOW",
  "pricing.pro-plan.action.guarantee": "30 days money-back guarantee",

  // Site Plan
  // -----------
  "pricing.site-plan.product": "ENTERPRISE",
  "pricing.site-plan.recommended-for": "COMPANIES",
  "pricing.site-plan.sub-title":
    "<strong>unlimited users</strong>{br}one time purchase",

  "pricing.site-plan.discount": "{price} until 2019/05/31",

  // Site Plan - enterprise section
  "pricing.site-plan.features.title.enterprise": "ENTERPRISE",
  "pricing.site-plan.features.virtualization":
    "Required for Citrix, TSM, VMWare VDI",
  "pricing.site-plan.features.virtualization-details":
    "or for large deploymentI",
  "pricing.site-plan.features.no-internet": "No Internet connection required",
  "pricing.site-plan.features.simple-activation": "Simple activation process",

  // Site Plan - action button
  "pricing.site-plan.action.contact-us": "Contact us",
  "pricing.site-plan.action.purpose": "for Quotation and Purchase Order",

  // ***********************************************
  // Volume discount
  // ***********************************************
  "volume-discount.title": "Volume discount",
  "volume-discount.sub-title":
    "Share the full featured version of screenpolo with all your team.",
  "volume-discount.quantity": "Quantity",
  "volume-discount.price": "Price",
  "volume-discount.buy": "Buy",
  "volume-discount.license-pack":
    "Pack: {count, number} {count, plural, one {license} other {licenses}}",
  "volume-discount.buy-for":
    "Buy for {count, number} {count, plural, one {user} other {users}}",
  "volume-discount.price-per-unit": "{price}",
  "volume-discount.discount": "{percentage} Discount",
  "volume-discount.save": "Save {value}",
  "volume-discount.enter-quantity": "Your number of users",
  "volume-discount.site-license": "Site license: unlimited users",
  "volume-discount.unlimited": "Buy for all users",

  // ***********************************************
  // Upgrade
  // ***********************************************
  "upgrade.title": "Upgrade screenpolo to the latest version.",
  "upgrade.sub-title": "Renew to get new features and updates for 1 more year.",
  "upgrade.enter-your-key": "Enter your license key to get your price",
  "upgrade.lost-your-key": "Lost your license key?",
  "upgrade.buy-new-one": "New customer? Buy a license",
  "upgrade.validate": "Validate your key",
  "upgrade.upgrade-plan": "Upgrade plan",
  "upgrade.renewal-price": "Renewal price",
  "upgrade.invalid-key": "Your key is not valid",
  "upgrade.pro": "Upgrade {name} for {price}",
  "upgrade.tax": "(incl. {tax} tax)",
  "upgrade.volume-discount": "volume discounts automatically apply.",

  // ***********************************************
  // Reseller
  // ***********************************************
  "reseller.title": "Reseller dedicated store",
  "reseller.sub-title":
    "Buy screenpolo on behalf of your customers.",
  "reseller.product-selection": "Select the product to order",
  "reseller.request-reseller-company": "Your company name (billing)",
  "reseller.request-reseller-email": "Your email (billing)",
  "reseller.request-end-user-company": "The end customer company name (for the license key)",
  "reseller.request-end-user-email": "The end customer contact email (for the license key)",

  "reseller.missing-product": "A product is not selected",
  "reseller.missing-product-quantity": "The quantity is empty",
  "reseller.missing-reseller-company": "The reseller company is empty",
  "reseller.invalid-missing-reseller-email": "The reseller email is empty or invalid",
  "reseller.missing-end-user-company-name": "The end-user company is empty",
  "reseller.invalid-missing-end-user-email": "The  end-user email is empty or invalid",

  "reseller.regular-price": "Regular price: {value} (incl. tax)",
  "reseller.discount": "Reseller discount (incl. volume discount): {value}",
  "reseller.total": "Total: {value} (incl. tax: {tax})",
  "reseller.user-quantity": "Number of end-users",

  // ***********************************************
  // Support
  // ***********************************************
  "support.title": "F.A.Q.",
  "support.sub-title": "Frequent Asked Questions",
  "support.categories.installation": "Installation",
  "support.categories.editor": "Built-in Editor",
  "support.categories.licensing": "Licensing",
  "support.categories.others": "Others",
  "support.categories.screenshot": "Screenshot",
  "support.categories.video-capture": "Video capture",
  "support.categories.troubleshooting": "Troubleshooting",
  "support.categories.upgrade": "Upgrades",
  "support.see-also": "See Also...",

  // ***********************************************
  // Company / About us
  // ***********************************************
  "company.title": "About Us",
  "company.pre-title":
    "We hand-craft screen capture solutions",
  "company.post-title":
    "Made In Toulouse",
  "company.who-are-we.title": "Who are we ?",
  "company.who-are-we.content.part1":
    "<strong>Screenpolo</strong> is edited by <jcbaey>Jean-Christophe Baey</jcbaey>",
  "company.who-are-we.content.part2":
    "{bjamin} (Director, on the left) and {jcbaey} (CEO, on the right, {twitter_jc}) are the co-founders of Learnpulse SAS. They have designed and developed screenpolo to fit productivity and ergonomic thanks to their respective experience in large companies.",
  "company.who-are-we.content.part3":
    "Learnpulse SAS is part of the Microsoft startup program {link}",
  "company.contact-us": "Contact us",
  "company.email.title": "E-mail",
  "company.email.content":
    "For assistance, support, commercial, press or investors:",
  "company.postal-address": "Postal address",
  "company.social-medias": "Social medias",
  "company.legal-information": "Legal information",
  "company.company-type": "(French simplified joint stock company)",
  "company.registration-number": "Registration Number (KBIS)",
  "company.capital": "Capital",
  "company.headquarters": "Registered headquarters",
  "company.siret": "SIRET",
  "company.vat": "VAT ID / Sale tax ID",

  // ***********************************************
  // Downloading
  // ***********************************************

  "downloading.title": "Downloading...",
  "downloading.sub-title": "Download will start in few seconds...",
  "downloading.content.title": "Thank you for downloading screenpolo !",
  "downloading.content.text":
    "If the download doesn’t start, click on the {link}.",
  "downloading.content.release-btn": "Read our release note",
  "downloading.link": "direct link",
  "downloading.link-title": "Click here to download",
  "subscribe-modal.title": "Don’t want to miss our latest news?",
  "subscribe-modal.placeholder": "your email",
  "subscribe-modal.text":
    "We'll send few emails to get you started. Unsubscribe at any time.",
  "subscribe-modal.skip": "Skip",
  "subscribe-modal.invalid-email": "Your email is empty or invalid",

  "releases.title": "Releases",
  "releases.sub-title": "All screenpolo releases",

  // ***********************************************
  // Components
  // ***********************************************

  "release-image.new": "NEW",
  "release-image.version": "VERSION AVAILABLE",

  // ***********************************************
  // Installation survey
  // ***********************************************
  "survey.install.title": "Installation survey",
  "survey.install.sub-title":
    "Please help us to know you better with a few questions!",
  "survey.install.time-to-complete":
    "Only 30 seconds of your time, we promise!",
  "survey.install.time-to-complete.subtitle": "You can skip any questions.",

  "survey.features": "What are your most used features of screenpolo?",
  "survey.features.screenshot": "Image capture (screenshot)",
  "survey.features.screencast": "Video capture (screencast)",
  "survey.features.img-editor": "Image editor",
  "survey.features.scrolling-capture": "Scrolling windows capture",
  "survey.features.sharing": "Sharing",

  "survey.where": "Where are you using screenpolo?",
  "survey.where.home": "At home",
  "survey.where.work": "At work",

  "survey.installation-count":
    "How many computers have you installed screenpolo on?",

  "survey.age": "Your age",

  "survey.from": "Where have you heard about screenpolo ? Free comments",
  "survey.tell-us-more": "Tell us more about you...",

  "survey.email": "Your email so that we can respond you!",

  "survey.social": "Don't forget to follow us on social networks",

  // ***********************************************
  // Survey components
  // ***********************************************
  "survey.submit": "Submit your responses",

  "survey.jobs": "Your job, area of work",
  "survey.jobs.architecture": "Architecture",
  "survey.jobs.cad": "CAD",
  "survey.jobs.education": "Education",
  "survey.jobs.graphics": "Graphics",
  "survey.jobs.management": "Management",
  "survey.jobs.sw": "Software Development",
  "survey.jobs.support": "Support",
  "survey.jobs.test": "Test",
  "survey.jobs.other": "Other",

  // ***********************************************
  // Uninstallation survey
  // ***********************************************
  "survey.uninstall.title": "Uninstallation survey",
  "survey.uninstall.sub-title":
    "Your opinion is critical to us! Please tell us why you uninstalled screenpolo.",
  "survey.uninstall.time-to-complete":
    "Only 30 seconds of your time, we promise!",
  "survey.uninstall.time-to-complete.subtitle": "You can skip any questions.",

  "survey.uninstall.reason": "Why did you uninstall screenpolo?",
  "survey.uninstall.reason.other": "Other reasons",
  "survey.uninstall.reason.re-install": "I will install it on another computer",
  "survey.uninstall.reason.new-version": "I want to update to latest version",
  "survey.uninstall.reason.missing-feature": "An important feature is missing",
  "survey.uninstall.reason.alternatives": "There are better alternatives",
  "survey.uninstall.reason.cpu-issue": "It uses too much CPU resources or is too slow",
  "survey.uninstall.reason.unstable": "It is not stable enough",
  "survey.uninstall.reason.antivirus-issue": "My firewall or antivirus alerts me",
  "survey.uninstall.reason.not-used": "I don’t use it anymore",

  "survey.uninstall-details": "Which feature is missing? Which error occurred? What should be changed? (free comments)",
  "survey.uninstall.email": "Your email so that we can respond you!",

  "survey.uninstall.missing-reason": "Please select why you uninstall",

  // ***********************************************
  // Thank you page
  // ***********************************************  
  "thank-you.title": "Thank You!",
  "thank-you.sub-title": "Many thanks for your time and your responses.",
  "thank-you.support": "Don't forget to have a look to our tutorials",
  "thank-you.support.button": "View more...",

  // ***********************************************
  // Purchase survey
  // ***********************************************
  "survey.purchase.title": "Purchase survey",
  "survey.purchase.sub-title":
    "Please help us to know you better with a few questions!",
  "survey.purchase.time-to-complete":
    "Only 30 seconds of your time, we promise!",
  "survey.purchase.time-to-complete.subtitle": "You can skip any questions.",
  
  "survey.purchase.reason": "What are your main reason for buying screenpolo ?",
  "survey.purchase.video-recording": "I record videos",
  "survey.purchase.screenshot": "I do a lot of captures",
  "survey.purchase.support-team": "I always buy software I use",
  "survey.purchase.sharing-watermark": "I need sharing or watermark features",
  "survey.purchase.support": "I want priority support",
  "survey.purchase.no-update": "I want to disable live updates",
  "survey.purchase.offline": "I want to use screenpolo offline",
  "survey.purchase.free": "I got it for free",
  "survey.purchase.other": "Other reason",

  // ***********************************************
  // Lost key
  // ***********************************************  
  "lost-key.title": "You have lost Your Software Key?",
  "lost-key.sub-title": "Try our automatic key lookup tool.",
  "lost-key.email": "Your e-mail address used when purchasing the activation key",
  "lost-key.submit": "Submit",
  "lost-key.explanation": "If we find keys from orders or registrations for that e-mail, we will send them to that address.",
  "lost-key.email-not-found": "The email {email} is not found on our license server.",
  "lost-key.email-sent": "Your license key has been sent to your email address {email}.",

  // ***********************************************
  // Form components
  // ***********************************************

  "form.fill-captcha": "Fill the captcha",
  "form.missing-recaptcha": "The captcha should be filled.",

  // ***********************************************
  // Cloud
  // ***********************************************
  "cloud.title": "screenpolo cloud",
  "cloud.sub-title": "Built-in sharing.",
  "cloud.total": "{count, number} {count, plural, one {media} other {medias}} shared on {date}",
  "cloud.invalid": "Oups, this address is not available or not valid!",
  "cloud.terms-of-use": "Terms of use",
  "cloud.report-abuse": "Report abuse",
  "cloud.media.dimensions": "Dimensions",
  "cloud.media.size": "Size",
};

module.exports = locale;