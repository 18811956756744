import React, { Fragment } from "react";

export const CompanyName = () => (<span className="font-semibold">JC Baey SARL</span>)

export const Address = () => (
  <Fragment>
    <CompanyName />
    <br />
    15 rue Théodore de Banville
    <br />
    31200 TOULOUSE
    <br />
    FRANCE
  </Fragment>
);