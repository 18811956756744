import axios from "axios";
import qs from "qs";

let baseApi;

// Skip build, the code should only run on browser side only
if (typeof window !== "undefined") {
  baseApi =
    window.location.hostname === "localhost"
      ? "https://p6lrluu0ei.execute-api.eu-west-1.amazonaws.com/v1"
      : "https://p6lrluu0ei.execute-api.eu-west-1.amazonaws.com/v1";
}

export const submitEmailRegistrationForm = formData => {
  const awsPromise = axios
    .post(`${baseApi}/emails`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
      validateStatus: status => status >= 200 && status <= 400,
    })
    .then(response => {
      if (response.status !== 200) {
        throw new Error(response.data.message);
      }
    });

  return awsPromise;
};
