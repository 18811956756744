import React, { Fragment } from "react";
import { Link } from "gatsby";
import { SiteMapContext } from "../layout";

export const I18Link = ({ children, id, autotext, ...others }) => {
  return (
    <SiteMapContext.Consumer>
      {sm => {
        if (
          !sm ||
          !sm.sitemap ||
          !sm.sitemap[id] ||
          !sm.sitemap[id][sm.locale] ||
          !sm.locale
        ) {
          return <Link {...others}>{children}</Link>;
        }

        const localized = sm.sitemap[id][sm.locale];
        return (
          <Link {...others} to={localized.path}>
            {autotext ? localized.text : ""}
            {children}
          </Link>
        );
      }}
    </SiteMapContext.Consumer>
  );
};

export const I18Text = ({ id, ...others }) => {
  return (
    <SiteMapContext.Consumer>
      {sm => {
        if (
          !sm ||
          !sm.sitemap ||
          !sm.sitemap[id] ||
          !sm.sitemap[id][sm.locale] ||
          !sm.locale
        ) {
          return null;
        }

        const localized = sm.sitemap[id][sm.locale];
        return <Fragment>{localized.text}</Fragment>;
      }}
    </SiteMapContext.Consumer>
  );
};
