const locale = {
  // ***********************************************
  // URLs
  // ***********************************************
  "page.index.url": "",
  "page.index.name": "ホーム",
  "page.index.title":
    "Screenpolo：生産性のためのスクリーンキャプチャツール",

  "page.features.url": "特徴",
  "page.features.name": "特徴",
  "page.features.title": "スクリーンプレッサーの特徴",

  "page.pricing.url": "価格設定",
  "page.pricing.name": "価格設定",
  "page.pricing.title": "スクリーンプレッサーの価格",

  "page.upgrade.url": "アップグレード",
  "page.upgrade.name": "アップグレードする",
  "page.upgrade.title": "最新バージョンにアップグレード",

  "page.volume-discount.url": "ボリュームディスカウント",
  "page.volume-discount.name": "ボリュームディスカウント",
  "page.volume-discount.title": "ボリュームディスカウント",

  "page.download.url": "ダウンロード",
  "page.download.name": "ダウンロード",
  "page.download.title": "スクリーンプレッサーをダウンロード",

  "page.downloading.url": "downloading-exe",
  "page.downloading.name": "screenpolo.exeのダウンロード",
  "page.downloading.title": "ダウンロード中...",

  "page.downloading-msi.url": "downloading-msi",
  "page.downloading-msi.name": "msiのダウンロード",
  "page.downloading-msi.title": "MSIのダウンロード...",

  "page.company.url": "私たちに関しては",
  "page.company.name": "私たちに関しては",
  "page.company.title": "私たちに関しては",

  "page.support.url": "サポート",
  "page.support.name": "サポート",
  "page.support.title": "ヘルプセンター",

  "page.privacy-policy.url": "個人情報保護方針",
  "page.privacy-policy.name": "個人情報保護方針",
  "page.privacy-policy.title": "個人情報保護方針",

  "page.affiliate-program.url": "アフィリエイトプログラム",
  "page.affiliate-program.name": "アフィリエイトプログラム",
  "page.affiliate-program.title": "アフィリエイトプログラム",

  "page.releases.url": "リリース",
  "page.releases.name": "リリース",
  "page.releases.title": "スクリーンプレッサーリリース",

  "page.news.url": "ニュース",
  "page.news.name": "新着情報？",
  "page.news.title": "Screenpolo：何が新しいのですか？",

  "page.third-parties.url": "third-parties",
  "page.third-parties.name": "第三者",
  "page.third-parties.title": "第三者",

  "page.software-license-agreement.url": "ソフトウェアライセンス契約",
  "page.software-license-agreement.name": "ソフトウェア使用許諾契約書",
  "page.software-license-agreement.title": "ソフトウェア使用許諾契約書",

  "page.translation.url": "翻訳",
  "page.translation.name": "翻訳",
  "page.translation.title": "翻訳者について",

  "page.install-survey.url": "インストール調査",
  "page.install-survey.name": "利用開始の簡単なアンケート",
  "page.install-survey.title": "スクリーンプレッサーのアンケート",

  "page.uninstall-survey.url": "アンインストール - アンケート",
  "page.uninstall-survey.name": "アンインストール時のアンケート",
  "page.uninstall-survey.title": "screenpoloのアンインストール/終了の調査",

  "page.purchase-survey.url": "購入調査",
  "page.purchase-survey.name": "購入時のアンケート",
  "page.purchase-survey.title": "購入時のアンケート",

  "page.thank-you.url": "ありがとうございました",
  "page.thank-you.name": "ありがとうございました",
  "page.thank-you.title": "ありがとうございました",
  
  "page.lost-key.url": "失われた鍵",
  "page.lost-key.name": "ソフトウェアキーを検索",
  "page.lost-key.title": "ソフトウェアキーを検索",

  "page.reseller.url": "販売店",
  "page.reseller.name": "販売店",
  "page.reseller.title": "販売店特定店舗",

  "page.cloud.url": "cloud",
  "page.cloud.name": "Cloud",
  "page.cloud.title": "screenpolo Cloud",

  // ***********************************************
  // Header and footer
  // ***********************************************

  "top-menu.languages": "言語",

  "top-menu.support.license-section": "ライセンス",
  "top-menu.support.enterprise-section": "企業",
  "top-menu.support.support-section": "サポート",
  "top-menu.support.help-center-entry": "ヘルプセンター",


  "footer.site": "サイト",
  "footer.contact-us": "お問い合わせ",
  "footer.social-networks": "ソーシャルネットワーク",
  "footer.recent-releases": "最近のリリース",
  "footer.languages": "言語",
  "footer.copyrights": "All Rights Reserved",

  "footer.subscribe-newsletter.title": "ニュースレターを購読する",
  "footer.subscribe-newsletter.description": "毎週受信トレイに送信される最新のニュースとリリース。",
  "footer.subscribe-newsletter.email": "電子メールアドレス",
  "footer.subscribe-newsletter.enter-email": "メールアドレスを入力",
  "footer.subscribe-newsletter.subscribe": "申し込む",
  "footer.subscribe-newsletter.email-added": "あなたのメールアドレスが追加されました！",

  // ***********************************************
  // Home
  // ***********************************************

  "index.title": "画像とビデオの画面キャプチャ",
  "index.sub-title":
    "スクリーンショットは、同僚やクライアントに説明をする時間を節約できます。",
  "index.push-line": "スクリーンショットには1000語の価値があります！",
  "index.description.part-1":
    "<strong>screenpolo</strong>はPCのデスクトップをキャプチャするツールです。トレーニング用のドキュメント、コラボレーションデザインワーク、ITのバグレポートを作るのをサポートします。",
  "index.description.part-2":
    "screenpoloは、画像編集機能、ユーザーガイド生成機能およびクラウド共有機能を内蔵したスクリーンショット取得ツールです。",
  "index.main-button.text": "スクリーンプレッサーを無料で入手",
  "index.main-button.sub-text": "すべてのWindowsバージョン",
  "index.more-features-button": "もっと多くの機能を見る",
  "index.feature-highlight.capture.title": "ビデオや画像のキャプチャ",
  "index.feature-highlight.capture.content":
    "数回のクリックで<strong>あなたの画面に表示されているもの</strong>をキャプチャします。 {br}{br}ワークスペースで<strong>キャプチャ履歴</strong>を管理します。",

  "index.feature-highlight.editor.title":
    "見栄えの良い画像とドキュメントを作成する",
  "index.feature-highlight.editor.content":
    "screenpoloの<strong>組み込みの画像エディタ</strong>を使うと、スクリーンショットの中の強調したいポイントを目立たせることができます。{br}{br} <strong>ドキュメント生成機能</strong>で、画面キャプチャーと説明文付きのPDFドキュメントを作成します。",

  "index.feature-highlight.sharing.title": "画面キャプチャを共有する",
  "index.feature-highlight.sharing.content":
    "簡単な操作でキャプチャした画像をクラウドサービスやSNSに共有ができます。",

  "index.testimonials.title": "お客様からの声",
  "index.referrals.title": "利用実績",
  "index.download-macos-version": "MacOバージョンをダウンロードする",

  // ***********************************************
  // Features
  // ***********************************************

  "features.title": "機能一覧",
  "features.pre-title": "キャプチャを制御します。",
  "features.post-title": "フィーチャーツアーをご覧ください。",

  // ***********************************************
  // Download
  // ***********************************************

  
  "download.title": "新しいバージョンを無料でダウンロードする",
  "download.pre-title": "新しいバージョンをダウンロードする",
  "download.post-title": "無料で",
  "download.download": "ダウンロード",

  "download.latest-version": "最新バージョン（{version}）",
  "download.release-note": "リリースノート",
  "download.user-manual": "ユーザーマニュアル",
  "download.not-supported": "MacOS、iOS、Androidはサポートされていません",
  "download.os-types": "32ビットと64ビット",
  "download.languages.main": "英語、日本語、スペイン語、フランス語、ドイツ語、{more}",
  "download.languages.more": "他15言語",
  "download.languages.rest":
    "イタリア語、簡体字中国語、繁体字中国語、トルコ語、グルジア語、ロシア語、ウクライナ語、ブラジル、ポルトガル語、アラビア語、ポーランド語、デンマーク語、オランダ語",
  "download.exe-prerequisites": "{dotnet}以上が必要です",
  "download.exe-button.text": "screenpoloをダウンロードする（{size}）",
  "download.exe-button.sub-text": "セットアップを含むポータブルアプリケーション",
  "download.msi-version": "エンタープライズ版（{version}）",
  "download.msi-description":
    "会社の複数のPCに簡単にscreenpoloをインストールするためのオールインワンパッケージ。",
  "download.msi-prerequisites":
    "{dotnet}と管理者権限が必要です。",
  "download.msi-button.text": "セットアップのダウンロード msi ({size})",
  "download.msi-button.sub-text": "企業展開に適しています",

  // ***********************************************
  // Release note
  // ***********************************************
  
  "release.whatsnew": "新着情報？",
  "release.tags.latest": "最新",
  "release.previous-release": "以前のリリース",
  "release.latest-release": "最新のリリース",
  "release.menu.older-releases": "過去のリリース情報を表示",
  "release.menu.all-releases": "すべてのリリース情報を表示",
  "release.download-button": "このバージョンをダウンロードする",
  "release.new-version-warning.header": "新しいバージョンが利用可能です",
  "release.new-version-warning.content":
  "最新バージョン（{version}）を見るには、ここをクリックしてください",
  "release.update.label": "更新を確認",
  "release.how-to-upgrade": "アップグレードする方法は？",
  "release.update.alternative": "または{checkForUpdate}を使って無料のアップデートを入手する",

  // ***********************************************
  // Blog
  // ***********************************************
  
  "blog.read-article": "全文を読む",
  

  // ***********************************************
  // Pricing
  // ***********************************************
  "pricing.title": "screenpoloを使って生産性を高めましょう！",
  "pricing.sub-title": "100万人以上のユーザーが使っています",
  "pricing.faq": "よくある質問",

  "pricing.plan.base": "基本機能",
  "pricing.plan.from":
    "screenpolo {version}のすべての機能が含まれています",
  "pricing.tax": "{tax}税抜価格",
  "pricing.black-friday": "<p> screenpolo PROのコード<strong>BLACK_FRIDAY_2019</strong>で50％割引</p>",

  // Free Plan
  // -----------
  "pricing.free-plan.product": "無料版",
  "pricing.free-plan.recommended-for": "個人向け",
  "pricing.free-plan.sub-title": "最新バージョンに{br}自動更新です",
  "pricing.free-plan.price": "0円",
  "pricing.free-plan.features.image-capture": "画像キャプチャ",
  "pricing.free-plan.features.video-capture": "HDビデオキャプチャ",
  "pricing.free-plan.features.branding": "（ビデオにロゴが入ります）",
  "pricing.free-plan.features.limited-editor": "画像エディタ（機能制限あり）",
  "pricing.free-plan.features.at-work": "職場での使用可能",

  // Free Plan - action button
  "pricing.free-plan.action.get": "無料版を入手",

  // Pro Plan
  // -----------
  "pricing.pro-plan.product": "プロフェッショナル版",
  "pricing.pro-plan.recommended-for": "個人やスモールビジネス向け",
  "pricing.pro-plan.sub-title":
    "ユーザーごとに購入",

  // Pro Plan - capture section
  "pricing.pro-plan.features.title.capture": "キャプチャー機能",
  "pricing.pro-plan.features.video-capture": "HDビデオキャプチャ",
  "pricing.pro-plan.features.video-capture-details":
    "システムの録音が可能",
  "pricing.pro-plan.features.android-capture": "Androidのキャプチャ",
  "pricing.pro-plan.features.text-capture": "画像からのテキストキャプチャ（OCR）",
  "pricing.pro-plan.features.color-picker": "カラーピッカー",

  // Pro Plan - edit, organize and share section
  "pricing.pro-plan.features.title.edit-organize": "画像編集、ワークスペース機能",
  "pricing.pro-plan.features.editor": "フル機能の画像エディタ",
  "pricing.pro-plan.features.editor-details":
    "再編集、エフェクト、ウォーターマークを含む",
  "pricing.pro-plan.features.doc-generator": "word, pdfドキュメント生成",
  "pricing.pro-plan.features.video-editor": "ビデオクリップとマージ",
  "pricing.pro-plan.features.video-editor-details": "（開始および終了）",
  "pricing.pro-plan.features.workspaces": "複数のワークスペース",
  "pricing.pro-plan.features.sharing": "クラウド・SNS共有機能",

  // Pro Plan - pro section
  "pricing.pro-plan.features.title.pro": "プロ",
  "pricing.pro-plan.features.support": "優先サポート（1年）",
  "pricing.pro-plan.features.policies": "ポリシー",
  "pricing.pro-plan.features.policies-details":
    "（管理者が一部の機能を無効にするため）",

  // Pro Plan - action button
  "pricing.pro-plan.action.buy": "購入ページへ",
  "pricing.pro-plan.action.guarantee": "30日間の返金保証",

  // Site Plan
  // -----------
  "pricing.site-plan.product": "企業",
  "pricing.site-plan.recommended-for": "大企業向け",
  "pricing.site-plan.sub-title":
    "<strong>ユーザー数　無制限</strong>",

  "pricing.site-plan.discount": "{price}から2019/05/31まで",

  // Site Plan - enterprise section
  "pricing.site-plan.features.title.enterprise": "企業",
  "pricing.site-plan.features.virtualization":
    "Required for Citrix, TSM, VMWare VDI",
  "pricing.site-plan.features.virtualization-details":
    "または大規模な展開のために",
  "pricing.site-plan.features.no-internet": "インターネットに接続する必要はありません",
  "pricing.site-plan.features.simple-activation": "簡単なアクティベーションプロセス",

  // Site Plan - action button
  "pricing.site-plan.action.contact-us": "お問い合わせ",
  "pricing.site-plan.action.purpose": "見積および購買発注の場合",

  // ***********************************************
  // Volume discount
  // ***********************************************
  "volume-discount.title": "大量割引",
  "volume-discount.sub-title":
    "screenpoloのプロフェッショナル版をチーム全員で使いましょう。",
  "volume-discount.quantity": "ライセンス数",
  "volume-discount.price": "価格",
  "volume-discount.buy": "購入",
  "volume-discount.license-pack":
    "{count}ライセンス パック",
  "volume-discount.buy-for":
    "{count} ユーザー分を購入",
  "volume-discount.price-per-unit": "{price}",
  "volume-discount.discount": "{percentage}割引",
  "volume-discount.save": "{value}円の節約",
  "volume-discount.enter-quantity": "必要なユーザー数",
  "volume-discount.site-license": "無制限のユーザー一度の購入",
  "volume-discount.unlimited": "すべてのユーザーのために購入",

  // ***********************************************
  // Upgrade
  // ***********************************************
  "upgrade.title": "screenpoloを最新バージョンにアップグレードします。",
  "upgrade.sub-title": "更新して新機能とアップデートを1年間お届けします。",
  "upgrade.enter-your-key": "価格を取得するには、ライセンスキーを入力してください",
  "upgrade.lost-your-key": "ライセンスキーを紛失しましたか？",
  "upgrade.buy-new-one": "新規のお客様ですか？ライセンスを購入する",
  "upgrade.validate": "キーを検証する",
  "upgrade.upgrade-plan": "アップグレード計画",
  "upgrade.renewal-price": "リニューアル価格",
  "upgrade.invalid-key": "キーが無効です",
  "upgrade.pro": "{price}の{name}をアップグレード",
  "upgrade.tax": "{tax}税抜価格",
  "upgrade.volume-discount": "ボリュームディスカウントが自動的に適用されます。",

  // ***********************************************
  // Reseller
  // ***********************************************
  "reseller.title": "販売店専用店",
  "reseller.sub-title":
    "あなたの顧客に代わってscreenpoloを購入する。",
  "reseller.product-selection": "注文する製品を選択してください",
  "reseller.request-reseller-company": "あなたの会社名（請求先）",
  "reseller.request-reseller-email": "あなたのEメール（請求）",
  "reseller.request-end-user-company": "エンドカスタマーの会社名（ライセンスキー用）",
  "reseller.request-end-user-email": "エンドカスタマーのメールアドレス（ライセンスキー用）",

  "reseller.missing-product": "商品が選択されていません",
  "reseller.missing-product-quantity": "数量が空です",
  "reseller.missing-reseller-company": "販売会社は空にできません",
  "reseller.invalid-missing-reseller-email": "販売代理店のメールアドレスが空か無効です",
  "reseller.missing-end-user-company-name": "エンドユーザーの会社は空にできません",
  "reseller.invalid-missing-end-user-email": "エンドユーザーのメールアドレスが空か無効です",

  "reseller.regular-price": "通常価格：{value}（税込）",
  "reseller.discount": "再販業者割引（数量割引を含む）：{value}",
  "reseller.total": "合計：{value}（税：{tax}を含む）",
  "reseller.user-quantity": "エンドユーザー数",

  // ***********************************************
  // Support
  // ***********************************************
  "support.title": "よくある質問",
  "support.sub-title": "よくある質問",
  "support.categories.installation": "インストール",
  "support.categories.editor": "組み込みエディタ",
  "support.categories.licensing": "ライセンス",
  "support.categories.others": "その他",
  "support.categories.screenshot": "スクリーンショット",
  "support.categories.video-capture": "ビデオキャプチャ",
  "support.categories.troubleshooting": "トラブルシューティング",
  "support.categories.upgrade": "アップグレード",
  "support.see-also": "関連項目...",

  // ***********************************************
  // Company / About us
  // ***********************************************
  "company.title": "screenpoloはLearnpulse SASによって編集されています",
  "company.pre-title":
    "スクリーンキャプチャソリューションを手作りします",
  "company.post-title":
    "トゥールーズ製",
  "company.who-are-we.title": "私たちは誰ですか ？",
  "company.who-are-we.content.part1":
    "<strong>screenpolo</strong>は2012年に設立された<strong>LEARNPULSE SAS</strong>によって編集されています。",
  "company.who-are-we.content.part2":
    "Learnpulse SASの共同設立者は、{bjamin}（Director、左側）と{jcbaey}（CEO、右側、{twitter_jc}）です。彼らは大企業でのそれぞれの経験に基づいて生産性と人間工学に感謝するために、screenpoloを設計し開発しました。",
  "company.who-are-we.content.part3":
    "Learnpulse SASはMicrosoftのスタートアッププログラムの一部です{link}",
  "company.contact-us": "お問い合わせ",
  "company.email.title": "Eメール",
  "company.email.content":
    "支援、商業、報道関係者、投資家のために：",
  "company.postal-address": "郵便住所",
  "company.social-medias": "社会的メディア",
  "company.legal-information": "法律情報",
  "company.company-type": "（フランス簡易合同会社）",
  "company.registration-number": "登録番号（KBIS）",
  "company.capital": "資本",
  "company.headquarters": "登録本部",
  "company.siret": "SIRET",
  "company.vat": "VAT ID /販売税ID",

  // ***********************************************
  // Downloading
  // ***********************************************

  "downloading.title": "ダウンロード中...",
  "downloading.sub-title": "ダウンロードは数秒で開始されます...",
  "downloading.content.title": "screenpoloをダウンロードしていただき、ありがとうございます！",
  "downloading.content.text":
    "ダウンロードが開始されない場合は、{link}をクリックしてください。",
  "downloading.content.release-btn": "リリースノートを読む",
  "downloading.link": "直接リンク",
  "downloading.link-title": "ダウンロードするにはこちらをクリック",
  "subscribe-modal.title": "メールマガジンの購読",
  "subscribe-modal.placeholder": "あなたのメールアドレス",
  "subscribe-modal.text":
    "最新ニュースのメールマガジンを送ります。いつでも停止することができます。",
  "subscribe-modal.skip": "スキップ",
  "subscribe-modal.invalid-email": "メールアドレスが空または無効です",

  "releases.title": "リリース",
  "releases.sub-title": "すべてのscreenpoloリリース",

  // ***********************************************
  // Components
  // ***********************************************

  "release-image.new": "新しい",
  "release-image.version": "使用可能なバージョン",

  // ***********************************************
  // Installation survey
  // ***********************************************
  "survey.install.title": "設置調査",
  "survey.install.sub-title":
    "ユーザの声をとても大切にしてます。アンケートにご協力お願いします。",
  "survey.install.time-to-complete":
    "アンケートに掛かる時間はたった30秒です",
  "survey.install.time-to-complete.subtitle": "質問は飛ばすことができます。",

  "survey.features": "screenpoloの最もよく使われている機能は何ですか？",
  "survey.features.screenshot": "画像キャプチャ（スクリーンショット）",
  "survey.features.screencast": "ビデオキャプチャ（スクリーンキャスト）",
  "survey.features.img-editor": "イメージエディタ",
  "survey.features.scrolling-capture": "スクロールウィンドウのキャプチャ",
  "survey.features.sharing": "クラウド、SNS共有",

  "survey.where": "スクリーンプレッサーをどこで使っていますか？",
  "survey.where.home": "家で",
  "survey.where.work": "職場で",

  "survey.installation-count":
    "スクリーンプレッサーをインストールしたコンピュータは何台ですか？",

  "survey.age": "あなたの年齢",

  "survey.from": "あなたはスクリーンプレッサーについてどこで聞いたことがありますか？フリーコメント",
  "survey.tell-us-more": "あなたについてもっと教えてください...",

  "survey.email": "あなたのメールアドレス",

  "survey.social": "ソーシャルネットワークでフォローをお願いします！",

  // ***********************************************
  // Survey components
  // ***********************************************
  "survey.submit": "回答を送信する",

  "survey.jobs": "あなたの仕事、仕事の範囲",
  "survey.jobs.architecture": "建築",
  "survey.jobs.cad": "CAD",
  "survey.jobs.education": "教育",
  "survey.jobs.graphics": "グラフィックス",
  "survey.jobs.management": "マネジメント",
  "survey.jobs.sw": "ソフトウェア開発",
  "survey.jobs.support": "サポート",
  "survey.jobs.test": "テスト",
  "survey.jobs.other": "その他",

  // ***********************************************
  // Uninstallation survey
  // ***********************************************
  "survey.uninstall.title": "アンインストールのアンケート",
  "survey.uninstall.sub-title":
    "ユーザの声を大切にしています！ screenpoloをアンインストールした理由を教えてください。",
  "survey.uninstall.time-to-complete":
    "アンケートに掛かる時間はたった30秒です",
  "survey.uninstall.time-to-complete.subtitle": "質問は飛ばすことができます。",

  "survey.uninstall.reason": "なぜscreenpoloをアンインストールしましたか？",
  "survey.uninstall.reason.other": "その他の理由",
  "survey.uninstall.reason.re-install": "別のコンピュータにインストールするため",
  "survey.uninstall.reason.new-version": "最新バージョンに更新するため",
  "survey.uninstall.reason.missing-feature": "機能が不足しているため",
  "survey.uninstall.reason.alternatives": "他のソフトに乗り換えたため",
  "survey.uninstall.reason.cpu-issue": "CPUリソースを使いすぎる。動作が遅すぎる",
  "survey.uninstall.reason.unstable": "不安定なため",
  "survey.uninstall.reason.antivirus-issue": "ファイアウォールやウイルス対策が警告するため",
  "survey.uninstall.reason.not-used": "もう使わなくなったため",

  "survey.uninstall-details": "どの機能が欠けていますか？どのエラーが発生しましたか？何を変更する必要がありますか？ （自由コメント）",
  "survey.uninstall.email": "返信するためのメールアドレス",

  "survey.uninstall.missing-reason": "アンインストールした理由を選択してください",

  // ***********************************************
  // Thank you page
  // ***********************************************  
  "thank-you.title": "ありがとうございました",
  "thank-you.sub-title": "ご回答、ありがとうございました。",
  "thank-you.support": "チュートリアルも見てください",
  "thank-you.support.button": "もっと見る...",

  // ***********************************************
  // Purchase survey
  // ***********************************************
  "survey.purchase.title": "購入調査",
  "survey.purchase.sub-title":
    "ユーザの声をとても大切にしてます。アンケートにご協力お願いします。",
  "survey.purchase.time-to-complete":
    "アンケートに掛かる時間はたった30秒です",
  "survey.purchase.time-to-complete.subtitle": "質問は飛ばすことができます。",
  
  "survey.purchase.reason": "screenpoloを購入する主な理由は何ですか？",
  "survey.purchase.video-recording": "ビデオを記録するため",
  "survey.purchase.screenshot": "スクリーンショットを取るため",
  "survey.purchase.support-team": "私はいつも私が使用するソフトウェアを購入する",
  "survey.purchase.sharing-watermark": "共有機能・透かし機能を使うため",
  "survey.purchase.support": "サポートが必要なため",
  "survey.purchase.no-update": "ライブアップデートを無効にしたい",
  "survey.purchase.offline": "screenpoloをオフラインで使用したい",
  "survey.purchase.free": "無料で手に入れた",
  "survey.purchase.other": "その他",

  // ***********************************************
  // Lost key
  // ***********************************************  
  "lost-key.title": "ライセンスキーを紛失しましたか？",
  "lost-key.sub-title": "ライセンスキー検索ツールをお試しください。",
  "lost-key.email": "ライセンスキー購入時に使用したメールアドレス",
  "lost-key.submit": "送信する",
  "lost-key.explanation": "指定のメールアドレスで登録されたライセンスキーが見つかったら、メールでお知らせします。",
  "lost-key.email-not-found": "メールアドレス（{email}）は当社のライセンスサーバー上に見つかりません。",
  "lost-key.email-sent": "ライセンスキーがメールアドレスに送信されました（{email}）。",

  // ***********************************************
  // Form components
  // ***********************************************

  "form.fill-captcha": "キャプチャを埋める",
  "form.missing-recaptcha": "キャプチャはいっぱいになるはずです。",

  // ***********************************************
  // Cloud
  // ***********************************************
  "cloud.title": "screenpoloクラウド",
  "cloud.sub-title": "組み込みの共有。",
  "cloud.total": "{count, number} {count, plural, one {media} other {medias}} shared on {date}",
  "cloud.invalid": "このアドレスは利用できないか無効です！",
  "cloud.terms-of-use": "利用規約",
  "cloud.report-abuse": "不正行為を報告",
  "cloud.media.dimensions": "外形寸法",
  "cloud.media.size": "サイズ",
};

module.exports = locale;