import { useState } from "react";
import formatError from "../../utils/gql-utils";

const useForm = ({ validateForm, initialValues = {}, onResponse, onDirty }) => {
  const [state, setState] = useState(initialValues);
  const onInputChange = (e) => {
    e.preventDefault();
    onDirty && onDirty();
    const { id, name, value, checked } = e.target;
    const newState = {
      [name || id]: value !== undefined ? value : checked,
      errors: undefined,
    };

    setState((currentState) => ({ ...currentState, ...newState }));
  };

  const onError = (error) => {
    setState((currentState) => ({
      ...currentState,
      loading: false,
      errors: undefined,
      errorMessages: formatError(error),
    }));
  };

  const onCompleted = (data) => {
    setState((currentState) => ({ ...currentState, loading: false }));
    onResponse && onResponse(data);
  };

  const onSubmit = (e, mutation) => {
    e.preventDefault();
    onDirty && onDirty();
    const { errorMessages, isValid, errors } = validateForm(state);
    setState((currentState) => ({
      ...currentState,
      loading: isValid,
      errors,
      errorMessages,
    }));

    if (isValid && mutation) {
      const formData = Object.assign({}, state);
      delete formData.loading;
      delete formData.errors;
      delete formData.errorMessages;
      return mutation(formData)
        .then(() => {
          setState(initialValues);
        })
        .then(onCompleted)
        .catch((error) => {
          errorMessages = errorMessages || [];
          errorMessages.push(error.message);
          setState({ loading: false, errors, errorMessages });
        });
    } 

    return Promise.resolve()
  };

  return [state, onInputChange, onSubmit, onCompleted, onError];
};

export default useForm;
